import { RouteProps } from 'react-router-dom';
import { StoreClass } from './mobx';

type Store<T extends { new (): InstanceType<T> }> = Readonly<{
  readonly class: T;
  readonly value: InstanceType<T>;
}>;

export type AppModuleStoreList = Store<any>[];

export interface AppModule {
  key: string;
  label: string;
  route?: RouteProps;
  stores?: AppModuleStoreList;
}

export function createStore<T extends StoreClass>(store: T): Store<T> {
  const _store = {
    class: store,
    _value: null,
    get value() {
      if (!this._value) {
        (this._value as any) = new store();
        Object.freeze(this);
      }
      return this._value as InstanceType<T>;
    },
  };

  return _store as Store<T>;
}
