import { Logger as LogLevelLogger } from 'loglevel';
import {
  Attributes,
  BaseEventBusPayload,
  EventBus,
  EventBusListener,
  EventBusSubscription,
} from '../types';
import { Logger } from '../utils';

/**
 * Implementation of EventBus using BroadcastChannel for cross-window communication.
 */
export class BroadcastChannelEventBus implements EventBus {
  private logger: LogLevelLogger;
  private channel: BroadcastChannel;
  private listeners: Map<string, Set<EventBusListener>> = new Map();
  private eventQueue: Map<string, BaseEventBusPayload[]> = new Map();

  constructor(channelName: string, logger: LogLevelLogger) {
    this.logger = Logger.withPrefix(logger, 'EventBus');

    try {
      this.logger.debug(`Creating BroadcastChannel with name "${channelName}"`);
      this.channel = new BroadcastChannel(channelName);
      this.channel.onmessage = event => this.handleMessage(event);
      this.channel.onmessageerror = err =>
        this.logger.error('NizzaBroadcastChannel error:', err);
    } catch (error) {
      this.logger.error('Failed to create NizzaBroadcastChannel:', error);
      throw error;
    }
  }

  /**
   * Subscribe a listener function to a specific event with wildcards.
   *
   * @param {string} pattern - The event name to subscribe to. You can use wildcards like 'user:*' or 'chat:**'.
   * @param {EventBusListener} listener - The listener function to handle the event.
   * @returns {EventBusSubscription} - A subscription object that includes the event name and the `off()` function for unsubscribing.
   * @example
   * const subscription = eventBus.on('user:*', (event) => {
   *   console.log('User event:', event.name);
   * });
   * const chatSubscription = eventBus.on('chat:**', (event) => {
   *   console.log('Chat event:', event.name);
   * });
   */
  on(pattern: string, listener: EventBusListener): EventBusSubscription {
    this.logger.debug(`Subscribing to pattern "${pattern}"`);
    this.subscribeToEvent(pattern, listener);
    return {
      off: () => this.unsubscribeFromEvent(pattern, listener),
    };
  }

  /**
   * Emit an event through the BroadcastChannel.
   *
   * @param {string} event - The event name to emit.
   * @param {any} data - The data associated with the event.
   * @param {Attributes} attrs - Optional attributes associated with the event.
   * @example
   * // Emit a user login event
   * eventBus.emit('user:login', { username: 'user123' });
   *
   * // Emit a chat message event
   * eventBus.emit('chat:message', { text: 'Hello, world!' });
   */
  emit(event: string, data: any, attrs: Attributes = {}): void {
    this.logger.debug(`Emitting event "${event}" with data`, data);
    this.emitEvent(event, data, attrs);
  }

  /**
   * Handle messages received through the BroadcastChannel and call the corresponding listener functions.
   *
   * @private
   * @param {MessageEvent} event - The received message event.
   */
  private handleMessage(event: MessageEvent): void {
    const basePayload = event.data as BaseEventBusPayload;
    const { name } = basePayload;
    this.logger.debug(`Received message "${name}" with data`, basePayload);

    for (const [pattern, listeners] of this.listeners.entries()) {
      if (this.matchWildcard(pattern, name)) {
        this.logger.debug(`Matching pattern "${pattern}" for event "${name}"`);
        listeners.forEach(listener =>
          listener({ ...basePayload, wildcard: pattern }),
        );
      }
    }
  }

  /**
   * Subscribe a listener function to a specific event.
   *
   * @private
   * @param {string} pattern - The pattern to subscribe to.
   * @param {EventBusListener} listener - The listener function to handle the event.
   */
  private subscribeToEvent(pattern: string, listener: EventBusListener): void {
    if (typeof pattern !== 'string' || typeof listener !== 'function') {
      const err = new Error('Invalid arguments');
      this.logger.error(err);
      throw err;
    }

    if (!this.listeners.has(pattern)) {
      this.logger.debug(`Adding new pattern "${pattern}"`);
      this.listeners.set(pattern, new Set());
    }

    const pendingEvents = Array.from(this.eventQueue)
      .filter(([key]) => {
        const matched = this.matchWildcard(pattern, key);
        if (matched) {
          this.eventQueue.delete(key);
          this.logger.debug(`Dequeued pending events for "${key}".`);
        }
        return matched;
      })
      .flatMap(([, value]) => value);

    for (const [wildcard, listeners] of this.listeners.entries()) {
      if (this.matchWildcard(wildcard, pattern)) {
        this.logger.debug(`Adding listener to pattern "${wildcard}"`);
        listeners.add(listener);

        if (pendingEvents.length) {
          this.logger.debug(`Delivering pending events for "${pattern}".`);
          pendingEvents.forEach(payload => {
            listener({ ...payload, wildcard });
          });
        }
      }
    }
  }

  /**
   * Emit an event and deliver it to the corresponding listeners.
   *
   * @private
   * @param {string} event - The event name to emit.
   * @param {any} data - The data associated with the event.
   * @param {Attributes} attrs - Optional attributes associated with the event.
   */
  private emitEvent(event: string, data: any, attrs: Attributes): void {
    if (typeof event !== 'string') {
      const err = new Error('Invalid arguments');
      this.logger.error(err);
      throw err;
    }

    const basePayload: BaseEventBusPayload = {
      name: event,
      data,
      attrs,
    };

    const listeners = Array.from(this.getMatchingListeners(event));

    if (listeners.length) {
      this.logger.debug(`Delivering event "${event}" to listeners`);
      for (const [pattern, listener] of listeners) {
        listener({ ...basePayload, wildcard: pattern });
      }
    } else {
      this.logger.debug(`No listeners for event "${event}", enqueuing`);
      if (!this.eventQueue.has(event)) {
        this.eventQueue.set(event, []);
      }
      this.eventQueue.get(event)!.push(basePayload);
    }

    this.logger.debug(`Posting message to channel`, basePayload);
    this.channel.postMessage(basePayload);
  }

  /**
   * Get listeners that match the event name pattern.
   *
   * @private
   * @param {string} event - The event name to match.
   * @returns {Iterable<[string, EventBusListener]>} - Iterable of matching listeners and their patterns.
   */
  private *getMatchingListeners(
    event: string,
  ): Iterable<[string, EventBusListener]> {
    this.logger.debug(`Getting matching listeners for event "${event}"`);
    for (const [pattern, listeners] of this.listeners.entries()) {
      if (this.matchWildcard(pattern, event)) {
        for (const listener of listeners) {
          yield [pattern, listener];
        }
      }
    }
  }

  /**
   * Unsubscribe a listener function from a specific event.
   *
   * @private
   * @param {string} event - The event name to unsubscribe from.
   * @param {EventBusListener} listener - The listener function to unsubscribe.
   */
  private unsubscribeFromEvent(
    event: string,
    listener: EventBusListener,
  ): void {
    if (this.listeners.has(event)) {
      this.logger.debug(`Unsubscribing from event "${event}"`);
      const listeners = this.listeners.get(event)!;
      listeners.delete(listener);

      if (listeners.size === 0) {
        this.logger.debug(`No more listeners for event "${event}", deleting`);
        this.listeners.delete(event);
      }
    }
  }

  /**
   * Match wildcards in event names.
   *
   * @private
   * @param {string} wildcard - The wildcard pattern to match.
   * @param {string} pattern - The event name to check.
   * @returns {boolean} - `true` if the event name matches the pattern, otherwise `false`.
   */
  private matchWildcard(wildcard: string, pattern: string): boolean {
    const regexPattern = wildcard
      .replace(/\*/g, '[^:]*') // * -> any value without :
      .replace(/\*\*/g, '.*'); // ** -> any value

    const regex = new RegExp(`^${regexPattern}$`);
    const matches = regex.test(pattern);
    this.logger.debug(
      `Wildcard "${wildcard}" matches with pattern "${pattern}": ${matches}`,
    );
    return matches;
  }
}
