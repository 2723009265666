import { ResourceNotFoundError, UnexpectedError } from '../../../errors';
import {
  AccountPlanRepository,
  NizzaAccount,
  PrivateAccountRepository,
  PrivateNizzaAccount,
  PublicAccountRepository,
} from '../domain';

export class AccountService {
  constructor(
    private publicAccountRepo: PublicAccountRepository,
    private privateAccountRepo: PrivateAccountRepository,
    private accountPlanRepo: AccountPlanRepository,
  ) {}

  // async get(name: string, authToken: string): Promise<PrivateNizzaAccount>;
  // async get(name: string, authToken?: undefined): Promise<NizzaAccount>;
  async get(
    name: string,
    authToken?: string,
  ): Promise<NizzaAccount | PrivateNizzaAccount> {
    try {
      const account = authToken
        ? await this.privateAccountRepo.get(name, authToken)
        : await this.publicAccountRepo.get(name);

      if (!account) {
        throw new ResourceNotFoundError(name);
      }

      const plan = await this.accountPlanRepo.get(account.descriptionPlan);

      if (!plan) {
        throw new ResourceNotFoundError(account.descriptionPlan);
      }

      return { ...account, plan };
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }
}
