import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

const _FINGERPRINT = 'FINGERPRINT_LIVE_SHOPPING'

export const getFingerprint = () => {
  let fingerprint = Cookies.get(_FINGERPRINT)

  if (fingerprint) return fingerprint

  fingerprint = uuidv4()
  Cookies.set(_FINGERPRINT, fingerprint, { expires: 1 })

  return fingerprint
}
