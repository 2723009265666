import { createAggregateRoot, IAggregateRoot, shortId } from '@nizza/core';
import { Product } from '../../../products';
import { CollectionInput, CollectionProps, CollectionStatus } from './types';

export class Collection implements IAggregateRoot, CollectionProps {
  readonly root = createAggregateRoot<CollectionProps, Collection>(this);

  id = '';
  account = '';
  name = '';
  description = '';
  status = CollectionStatus.ACTIVE;
  createdAt = new Date();
  products: Product[] = [];

  constructor(props: CollectionProps) {
    this.root.setProps(props);
  }

  static create(props: CollectionInput): Collection {
    const data: CollectionProps = {
      ...props,
      id: props.id ?? shortId(),
      description: props.description ?? '',
      createdAt: props.createdAt ? new Date(props.createdAt) : new Date(),
      status: props.status ?? CollectionStatus.ACTIVE,
      products: props.products ?? [],
    };
    return new Collection(data);
  }

  toggleStatus() {
    this.status =
      this.status === CollectionStatus.ACTIVE
        ? CollectionStatus.INACTIVE
        : CollectionStatus.ACTIVE;
  }

  get numProducts() {
    return this.products.length;
  }

  toPrimitives() {
    const { root, createdAt, products, ...rest } = this;
    return {
      ...rest,
      createdAt: createdAt.toDateString(),
      products: products.map(x => x.toPrimitives()),
    };
  }
}
