import { useNizza } from '../../nizza-store';
import { NizzaAccount } from '../core';

export const useAccount = <T = NizzaAccount>() => {
  const {
    account,
    accountName,
    accountStatus,
    refetchAccount,
    isPvtAccount,
    isPubAccount,
  } = useNizza(x => ({
    account: x.account as T | undefined,
    accountName: x.accountName,
    accountStatus: x.accountStatus,
    refetchAccount: x.refetchAccount,
    isPvtAccount: x.isPvtAccount,
    isPubAccount: x.isPubAccount,
  }));

  const isLoading = ['idle', 'loading'].includes(accountStatus);
  const isError = accountStatus === 'error';
  const isSuccess = accountStatus === 'success';

  return {
    account,
    accountName,
    isLoading,
    isError,
    isSuccess,
    accountStatus,
    refetchAccount,
    isPvtAccount,
    isPubAccount,
  };
};
