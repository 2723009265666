import { ThirdPartyModule, i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export function i18Init(i18Instance: i18n, initReactI18next: ThirdPartyModule) {
  i18Instance
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources: {},
      fallbackLng: 'en',
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: (value, rawFormat, lng): string => {
          if (rawFormat) {
            const [format, ...additionalValues] = rawFormat
              .split(',')
              .map(v => v.trim());

            if (format === 'number') {
              return new Intl.NumberFormat(lng).format(value);
            }

            if (format === 'date') {
              return new Intl.DateTimeFormat(lng).format(value);
            }

            if (format === 'currency') {
              return new Intl.NumberFormat(lng, {
                style: 'currency',
                currency: additionalValues[0] || 'USD',
                currencyDisplay: 'narrowSymbol',
              })
                .format(value)
                .replace('$', '')
                .trim();
            }
          }

          return value;
        },
      },
      detection: {
        // order and from where user language should be detected
        order: [
          'navigator',
          'cookie',
          'localStorage',
          'sessionStorage',
          'querystring',
          'htmlTag',
          'path',
          'subdomain',
        ],
      },
    });
}
