import { Logger as LogLevelLogger } from 'loglevel';
import {
  GlobalComponent,
  GlobalStore,
  GlobalStoreConfig,
  ModuleId,
  RenderHandler,
  RuntimeBundler,
  RuntimeManagerConfig,
  RuntimeMetadata,
  RuntimeType,
} from '../types';
import { Logger } from '../utils';
import { ComponentManager } from './component-manager';
import { runtimeList } from './implementations';
import {
  RuntimeConfigCallback,
  RuntimeConfigOptions,
  RuntimeCustomConfig,
} from './types';

export class Runtime<T extends GlobalStore> {
  private globalId: string;
  private metadata: RuntimeMetadata;
  private customConfigList: Map<RuntimeType, RuntimeCustomConfig>;
  env: Record<string, any>;
  bundler: RuntimeBundler;
  logger: LogLevelLogger;
  componentId!: ModuleId;
  renderHandler!: RenderHandler;
  store: T;

  constructor(config: RuntimeManagerConfig<T>) {
    this.globalId = config.globalId;
    this.store = config.getStore() as T;
    this.logger = Logger.withPrefix(config.logger, 'Runtime');
    this.env = config.env;
    this.bundler = config.bundler;
    this.customConfigList = new Map();
    this.metadata = { type: RuntimeType.External } as RuntimeMetadata;

    this.initializeGlobals();
  }

  config<K extends RuntimeType>(
    runtimeType: K,
    options: RuntimeConfigOptions,
    callback: RuntimeConfigCallback,
  ): this;

  config<K extends RuntimeType>(
    runtimeType: K,
    callback: RuntimeConfigCallback,
  ): this;

  config<K extends RuntimeType>(
    runtimeType: K,
    optionsOrCallback: RuntimeConfigOptions | RuntimeConfigCallback,
    callback?: RuntimeConfigCallback,
  ): this {
    if (typeof optionsOrCallback === 'function') {
      callback = optionsOrCallback;
      optionsOrCallback = {};
    }

    const { skipBaseBehavior = false } =
      optionsOrCallback as RuntimeConfigOptions;

    this.customConfigList.set(runtimeType, {
      callback: callback!,
      skipBaseBehavior,
    });

    return this;
  }

  registerComponent(componentId: string, render: RenderHandler) {
    const cmpId = this.toCamel(componentId);

    if ((window as any)[this.globalId][cmpId]) {
      this.logger.debug(`Component "${cmpId}" is already registered.`);
      return this;
    }

    this.componentId = { value: componentId, formatted: cmpId };
    this.renderHandler = render;

    const cmp: GlobalComponent = { render: this.renderHandler };
    (window as any)[this.globalId][this.componentId.formatted] = cmp;
    this.logger.debug(`Component "${cmpId}" registered.`);

    return this;
  }

  async execute(callback?: () => Promise<void> | void) {
    for (const Runtime of runtimeList) {
      if (Runtime.isApplicable(this)) {
        const runtimeInstance = new Runtime({ runtime: this });
        const runtimeConfig = this.customConfigList.get(Runtime.type);

        this.updateMeta({ type: Runtime.type });

        if (!runtimeConfig?.skipBaseBehavior) {
          await runtimeInstance.execute();
        }

        if (runtimeConfig) {
          await runtimeConfig.callback({
            runtime: this,
            component: new ComponentManager(
              this.componentId.value,
              this.renderHandler,
              this.logger,
            ),
          });
        }

        break;
      }
    }

    await callback?.();
  }

  updateMeta(data: Partial<RuntimeMetadata>) {
    this.metadata = { ...this.metadata, ...data };
  }

  async configureStore(config: GlobalStoreConfig) {
    await this.store.configure(config);
  }

  get meta() {
    return this.metadata;
  }

  private initializeGlobals() {
    if (!(window as any)[this.globalId]) {
      (window as any)[this.globalId] = {};
      this.logger.debug(`Initialized "${this.globalId}" window object`);
    }

    if ((window as any)[this.globalId]?.runtime) {
      this.logger.debug(
        `Runtime for "${this.globalId}" are already initialized.`,
      );
      return;
    }

    const getMeta = () => Object.freeze({ ...this.metadata });
    const getRuntime = () =>
      Object.freeze({
        isProd: this.bundler.isProd,
        get meta() {
          return getMeta();
        },
      });

    Object.defineProperty((window as any)[this.globalId], 'runtime', {
      get: () => getRuntime(),
    });

    this.logger.debug(`Initialized runtime for "${this.globalId}".`);
  }

  private toCamel(module: string) {
    return module
      .replace(/^[a-z]{2}-/, '')
      .replace(/-./g, x => x[1].toUpperCase());
  }
}
