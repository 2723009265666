import { searchParams } from '@nizza/core';
import env from '~env';
import { getPageUrl, isMyVTEX } from './utils';

const { apiUrls } = env;

const BASE_URL = 'api/catalog_system/pub/products/search';
const CORS_PROXY_URL = apiUrls.corsProxy;
const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 49;

type CatalogParams = {
  account?: string;
  accountHost?: string | null;
  productId?: string;
  collectionId?: string;
  search?: string;
  page?: number;
  limit?: number;
  corsProxy?: boolean;
  corsProxyOrigin?: string;
  salesChannelId?: string;
};

function buildPaginationParams(
  page: number = DEFAULT_PAGE,
  limit: number = DEFAULT_LIMIT,
): string {
  return `&_from=${page}&_to=${limit}`;
}

function buildProductFilter(productId: string) {
  return `?fq=productId:${productId}`;
}

function buildCollectionFilter(collectionId: string, paginationParams: string) {
  return `?fq=productClusterIds:${collectionId}${paginationParams}`;
}

/**
 * This function should work for next scenarios:
 *  1. When the environment is local (dev:local) or globalPage
 *  2. When the environment is MyVTEX (master or workspace)
 *  3. When the environment is VTEX but it use a host (usually vtex store in production)
 */
function buildBaseUrl(account: string, accountHost?: string | null) {
  const pageUrl = getPageUrl();

  if (!pageUrl) {
    throw new Error('Unable to retrieve the page URL');
  }

  const defaultHost = `${account}.myvtex.com`;
  const pageHost = new URL(pageUrl).host;
  const host = isMyVTEX(pageUrl) ? pageHost : accountHost ?? defaultHost;

  return `https://${host}/${BASE_URL}`;
}

function buildSearchPath(search: string) {
  return search ? `/${encodeURIComponent(search)}` : '';
}

function buildSalesChannelFilter(salesChannel: string) {
  return `&sc=${salesChannel}`;
}

function withCorsProxy(url: string, corsProxyOrigin?: string) {
  let params;

  if (url.includes('fq=productId:')) {
    params = searchParams({ url, origin: corsProxyOrigin });
  } else {
    const query = {
      brand: true,
      brandId: true,
      brandImageUrl: true,
      categoryId: true,
      description: true,
      link: true,
      linkText: true,
      metaTagDescription: true,
      productId: true,
      productName: true,
      productReference: true,
      productReferenceCode: true,
      productTitle: true,
      releaseDate: true,
      skuSpecifications: true,
      productClusters: true,
      items: {
        Color: true,
        'Color Proveedor': true,
        Talla: true,
        complementName: true,
        ean: true,
        estimatedDateArrival: true,
        images: {
          imageId: true,
          imageLabel: true,
          imageLastModified: true,
          imageTag: true,
          imageText: true,
          imageUrl: true,
        },
        isKit: true,
        itemId: true,
        measurementUnit: true,
        modalType: true,
        name: true,
        nameComplete: true,
        sellers: {
          addToCartLink: true,
          commertialOffer: {
            Price: true,
            ListPrice: true,
            IsAvailable: true,
            Tax: true,
          },
          sellerDefault: true,
          sellerId: true,
          sellerName: true,
        },
      },
    };

    const q = window.btoa(JSON.stringify(query));
    params = searchParams({ q, url, origin: corsProxyOrigin });
  }

  return `${CORS_PROXY_URL}${params}`;
}

export function buildVtexCatalogUrl(params: CatalogParams = {}): string {
  const {
    account,
    productId,
    collectionId,
    search,
    corsProxy = false,
    corsProxyOrigin,
    page = DEFAULT_PAGE,
    limit = DEFAULT_LIMIT,
    salesChannelId,
  } = params;

  if (!account) throw new Error('buildVtexCatalogUrl: account is required');

  const accountHost = corsProxy ? null : params.accountHost;
  const baseUrl = buildBaseUrl(account, accountHost);
  const paginationParams = buildPaginationParams(page, limit);
  let url = baseUrl;

  if (productId) {
    url = `${baseUrl}${buildProductFilter(productId)}`;
  }

  if (collectionId) {
    url = `${baseUrl}${buildCollectionFilter(collectionId, paginationParams)}`;
  }

  if (search) {
    url = `${baseUrl}${buildSearchPath(search)}`;
  }

  if (salesChannelId) {
    url = `${url}${buildSalesChannelFilter(salesChannelId)}`;
  }

  return corsProxy ? withCorsProxy(url, corsProxyOrigin) : url;
}
