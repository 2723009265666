import { AccountInvalidError, UnexpectedError } from '../../../../errors';
import { EventChatRoomRepository, GetChatRoomInput } from '../domain';

export class EventChatRoomService {
  private account!: string;

  constructor(private eventChatRoomRepository: EventChatRoomRepository) {}

  async get(input: Omit<GetChatRoomInput, 'account'>) {
    this.validateAccount();

    try {
      return await this.eventChatRoomRepository.getChatRoom({
        ...input,
        account: this.account,
      });
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }

  setAccount(value: string) {
    this.account = value;
  }

  private validateAccount() {
    if (!this.account) throw new AccountInvalidError();
  }
}
