import { GlobalStore, shortId } from '@nizza/core';

/**
 * Combines multiple glob style objects into a single object with unique keys.
 * This function is useful for avoiding style conflicts by creating unique
 *
 * @param {Record<string, any>[]} styles - An array of style objects, potentially deeply nested.
 * @returns A record with unique keys, where each key is a string
 * representing the unique class name, and the value is the style content.
 */
export function combineGlobStyles(
  styles: Record<string, any>[],
): Record<string, string> {
  const createUniqueKey = (path: string): string => {
    const parts = path.split('/').filter(Boolean);
    const fileName = parts.pop()?.split('.')[0] ?? '';
    const hash = shortId(5);
    return `${fileName}-${hash}`;
  };

  return styles
    .flat(Infinity)
    .reduce((acc: Record<string, string>, style: Record<string, string>) => {
      Object.entries(style).forEach(([path, value]) => {
        const uniqueKey = createUniqueKey(path);
        acc[uniqueKey] = value;
      });
      return acc;
    }, {});
}

export function configureGlobalStore<T extends GlobalStore[]>(
  stores: T,
  config: any,
) {
  return stores.map(store => {
    store && store.configure(config);
    return store;
  });
}

/**
 * Waits for a specified condition to become true within a timeout period.
 * It checks the condition at specified intervals.
 *
 * @param conditionFn A function that returns a boolean indicating whether the condition is met.
 * @param timeout The maximum time to wait for the condition to be met, in milliseconds. Defaults to 60,000ms (1 minute).
 * @param interval The interval at which to check the condition, in milliseconds. Defaults to 100ms.
 * @returns A promise that resolves when the condition is met or rejects if the timeout is exceeded.
 */
export function waitForCondition(
  conditionFn: () => boolean,
  timeout = 60000,
  interval = 100,
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (conditionFn()) {
      return resolve();
    }

    const startTime = Date.now();

    const checkCondition = setInterval(() => {
      if (conditionFn()) {
        clearInterval(checkCondition);
        resolve();
      } else if (Date.now() - startTime > timeout) {
        clearInterval(checkCondition);
        reject(new Error('Timeout waiting for condition.'));
      }
    }, interval);
  });
}

/**
 * Converts a string to camel case.
 *
 * @param str The string to convert.
 * @returns The camel case version of the string.
 */
export const toCamelCase = (str: string) =>
  str
    .replace(/([-_ ]\w)/g, match => match.charAt(1).toUpperCase())
    .replace(/(^\w)/, match => match.toLowerCase());
