import { ApiGraphqlTypes as AGT } from '@nizza/core';
import { createProduct } from '../../products';
import { Collection, CollectionInput, CollectionStatus } from '../core';

export const createCollection = (
  x: AGT.Collection | CollectionInput,
): Collection => {
  return Collection.create({
    id: x.id,
    account: x.account as string,
    name: x.name,
    createdAt:
      (x as AGT.Collection).createdat ?? (x as CollectionInput).createdAt,
    description: x.description || '',
    products: x.products?.map(createProduct) ?? [],
    status: (x.status ?? CollectionStatus.ACTIVE) as any,
  });
};
