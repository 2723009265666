import {
  AccountTypes,
  NizzaProductDataSource,
  NizzaProductDataSourceConfig,
  NizzaProductRequestOriginTypes,
  getNizza,
} from '@nizza/core';
import { when } from 'mobx';
import {
  CACEProductDataSource,
  PlatformProductDataSource,
  VtexProductDataSource,
} from './data-sources';

function createDataSource(
  config: NizzaProductDataSourceConfig,
): NizzaProductDataSource {
  const { account } = getNizza();
  const { accountType } = account!;
  const { origin } = config;

  const isVtex = [
    AccountTypes.VTEX,
    AccountTypes.VTEX_CMS,
    AccountTypes.VTEX_IO,
  ].includes(accountType);

  if (origin === NizzaProductRequestOriginTypes.CACE) {
    return new CACEProductDataSource(config);
  }

  if (accountType === AccountTypes.PLATFORM) {
    return new PlatformProductDataSource(config);
  }

  if (
    [
      NizzaProductRequestOriginTypes.GLOBAL_PAGE,
      NizzaProductRequestOriginTypes.UNKNOWN,
    ].includes(origin as NizzaProductRequestOriginTypes) &&
    isVtex
  ) {
    return new VtexProductDataSource({ ...config, corsProxy: true });
  }

  return new VtexProductDataSource({ ...config });
}

/**
 * Creates a product data source synchronously. This function assumes that
 * `nizza.account` is already defined and proceeds to create the data source
 * based on the provided configuration.
 *
 * @param {NizzaProductDataSourceConfig} config - The configuration object for creating the data source.
 * @returns {NizzaProductDataSource} The created product data source.
 * @throws {Error} Throws an error if `nizza.account` is not defined.
 *
 * @example
 * const config = {
 *   origin: NizzaProductRequestOriginTypes.GLOBAL_PAGE,
 * };
 *
 * try {
 *   const dataSource = createProductDataSource(config);
 *   console.log(dataSource);
 * } catch (error) {
 *   console.error('Nizza account is not defined:', error);
 * }
 */
export function createProductDataSource(
  config: NizzaProductDataSourceConfig,
): NizzaProductDataSource {
  const nizza = getNizza();
  if (!nizza.account) throw new Error('Nizza account is not defined');
  return createDataSource(config);
}

/**
 * Creates a product data source asynchronously. This function waits for
 * `nizza.account` to be defined if it is not already, and then proceeds to
 * create the data source based on the provided configuration. It is useful
 * in scenarios where `nizza.account` might not be immediately available.
 *
 * @param {NizzaProductDataSourceConfig} config - The configuration object for creating the data source.
 * @returns {Promise<NizzaProductDataSource>} A promise that resolves with the created product data source.
 *
 * @example
 * const config = {
 *   origin: NizzaProductRequestOriginTypes.DEV_LOCAL,
 * };
 *
 * createProductDataSourceAsync(config)
 *   .then(dataSource => console.log(dataSource))
 *   .catch(error => console.error('Error creating data source:', error));
 */
export async function createProductDataSourceAsync(
  config: NizzaProductDataSourceConfig,
): Promise<NizzaProductDataSource> {
  const nizza = getNizza();
  await when(() => !!nizza.account);
  return createDataSource(config);
}
