import { IAggregateRoot, createAggregateRoot, shortId } from '@nizza/core';
import { ProductInput, ProductProps, ProductStatus, SyncStatus } from './types';

export class Product implements IAggregateRoot, ProductProps {
  readonly root = createAggregateRoot<ProductProps, Product>(this);

  id = '';
  account = '';
  link = '';
  pictures: string[] = [];
  // Product price without discount
  salesPrice = 0;
  // Product price with discount
  price = 0;
  title = '';
  createdAt = new Date();
  status = ProductStatus.ACTIVE;
  // relationship with an external product that does not belong to LiveShopping
  externalId = null;

  /**
   * Generated props
   */
  hasOffer = false;
  offer = 0;
  detailLink = '';
  image = '';
  // synchronized within the collection
  syncStatus = SyncStatus.IDLE;

  constructor(props: ProductProps) {
    this.root.setProps(props);
  }

  static create(props: ProductInput): Product {
    const computedProps = Product.getComputedProps(props);
    const data: ProductProps = {
      ...props,
      ...computedProps,
      id: props.id ?? shortId(),
      status: props.status ?? ProductStatus.ACTIVE,
      createdAt: props.createdAt ? new Date(props.createdAt) : new Date(),
      syncStatus: SyncStatus.IDLE,
      image: '',
      detailLink: '',
    };

    return new Product(data);
  }

  private static getComputedProps(props: ProductInput) {
    const offer = props?.salesPrice
      ? Math.floor(((props.price - props.salesPrice) / props.price) * 100)
      : 0;
    const hasOffer = offer > 0;
    const image = props.pictures[0];

    return { offer, hasOffer, image };
  }

  toggleStatus() {
    this.status =
      this.status === ProductStatus.ACTIVE
        ? ProductStatus.DISABLED
        : ProductStatus.ACTIVE;
  }

  toPrimitives(withGeneratedProps = false) {
    const {
      root,
      createdAt,
      hasOffer,
      offer,
      detailLink,
      image,
      syncStatus,
      ...rest
    } = this;

    return {
      ...rest,
      ...(withGeneratedProps && {
        hasOffer,
        offer,
        detailLink,
        image,
        syncStatus,
      }),
      createdAt: createdAt.toISOString(),
    };
  }
}
