import { ApiGraphqlTypes as AGT } from '@nizza/core';
import { Product, ProductProps, ProductStatus } from '../core';
import { ProductREST } from './types';

export const createProduct = (
  x: ProductREST | AGT.Product | ProductProps,
): Product => {
  return Product.create({
    account: x.account,
    link: x.link ?? '',
    pictures: x.pictures || [],
    price: x.price,
    salesPrice:
      (x as ProductREST | AGT.Product).salesprice ??
      (x as ProductProps).salesPrice,
    title: x.title,
    createdAt: (x as ProductREST).createdtime ?? (x as AGT.Product).createdat,
    id: x.id,
    status: x.status as ProductStatus,
  });
};
