import { DomainError } from '../../../../../errors';

export class EventChatRoomTokenError extends DomainError {
  constructor() {
    super(
      'EVENT_CHAT_ROOM_TOKEN_ERROR',
      `Error getting chat room connection token`,
    );
  }
}
