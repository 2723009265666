type StorageType = 'localStorage' | 'sessionStorage';

export function isStorageAvailable(type: StorageType) {
  try {
    const storage = window[type];
    const data = '__custom-storage__';

    storage.setItem(data, data);
    storage.removeItem(data);
    return true;
  } catch (e) {
    return false;
  }
}

export function setProperty(obj: unknown, name: string, value: unknown) {
  const descriptor: PropertyDescriptor = {
    configurable: false,
    enumerable: false,
    writable: false,
  };
  if (typeof value !== 'undefined') {
    descriptor.value = value;
  }
  Object.defineProperty(obj, name, descriptor);
}
