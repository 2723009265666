import { useSetAtom } from 'jotai';
import { chatMessageListAtom } from '../state';
import { normalizeChatMessage } from '../utils';

/**
 * Handle event that can be performed with the event chat room.
 */
export const useChatSocketHandlers = () => {
  const setChatMessageList = useSetAtom(chatMessageListAtom);

  const handleEvent = (event: any) => {
    if (event.EventName === 'senddeletemessage') {
      const msgDelete = JSON.parse(event.Attributes?.data);
      setChatMessageList(prev => prev.filter(msg => msg.id !== msgDelete.id));
    }
  };

  const handleMessage = (data: any) => {
    setChatMessageList(prev => [...prev, normalizeChatMessage(prev, data)]);
  };

  return {
    handleEvent,
    handleMessage,
  };
};

export default useChatSocketHandlers;
