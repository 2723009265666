import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TranslationFiles } from '../types';
import { registerTranslationsOf } from './buildTranslations';
import { i18Init } from './init';

i18Init(i18next, initReactI18next);

export const registerTranslations = (translationFiles: TranslationFiles) =>
  registerTranslationsOf(i18next, translationFiles);
