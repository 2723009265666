/* eslint-disable react-hooks/exhaustive-deps */
import {
  EventBusListener,
  EventBusSubscription,
  GlobalStore,
} from '@nizza/core';
import { useEffect } from 'react';

/**
 * Custom hook for subscribing to events from an EventBus.
 * @param {string | string[]} events - Events to subscribe to.
 * @param {function} callback - Callback function to handle the events.
 */
export function useEventEffect(
  store: GlobalStore,
  events: string | string[],
  callback: EventBusListener,
) {
  useEffect(() => {
    const eventList = Array.isArray(events) ? events : [events];
    const subscriptions: EventBusSubscription[] = [];

    eventList.forEach(event => {
      const subscription = store.eventBus.on(event, callback);
      subscriptions.push(subscription);
    });

    return () => {
      // When the component unmounts, remove all subscriptions.
      subscriptions.forEach(subscription => {
        subscription.off();
      });
    };
  }, []);
}
