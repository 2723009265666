import { AccountVariationsConfig, VtexItem, VtexProduct } from '@nizza/core';

// Extend VtexItem to allow dynamic keys and optional variations
type Item = VtexItem & {
  variations?: (string | number)[];
  [key: string]: any;
};

export function omittedVariationsConfig(
  product: VtexProduct,
  variationsConfig: AccountVariationsConfig,
): VtexProduct {
  const omitted = variationsConfig.omitted;

  // If there are no items or skuSpecifications, return the original product
  if (
    (!product.items || product.items.length === 0) &&
    (!product.skuSpecifications || product.skuSpecifications.length === 0)
  ) {
    return product;
  }

  const omittedVariationsItems =
    product.items?.map(item => {
      const extendedItem = { ...item } as Item;

      // Remove omitted properties
      if (omitted?.length) {
        omitted.forEach(key => {
          delete extendedItem[key as keyof Item];
        });

        // Filter out omitted variations
        if (extendedItem.variations?.length) {
          extendedItem.variations = extendedItem.variations.filter(
            variation => !omitted.includes(String(variation)),
          );
        }
      }

      return extendedItem;
    }) ?? [];

  const omittedSkuSpecifications =
    product.skuSpecifications?.filter(
      spec => !omitted.includes(spec.field.name),
    ) ?? [];

  return {
    ...product,
    items: omittedVariationsItems,
    skuSpecifications: omittedSkuSpecifications,
  };
}
