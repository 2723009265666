import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Logger } from 'loglevel';
import { ErrorCodes, HttpClient, PostRequestInput } from '../../domain';
import { getUserFriendlyMessage } from '../../utils';

type Config = {
  baseUrl: string;
  logger: Logger;
  accessToken?: string;
};

export class HttpAxiosClient implements HttpClient {
  private logger: Logger;
  private axiosInstance: AxiosInstance;

  constructor(config: Config) {
    this.logger = config.logger;
    this.axiosInstance = axios.create({
      baseURL: config.baseUrl,
      headers: config.accessToken
        ? { Authorization: `Bearer ${config.accessToken}` }
        : {},
    });
  }

  /**
   * Performs a GET request to the specified path.
   *
   * @param {string} path - The endpoint path.
   * @returns {Promise<T>} - The response data.
   * @throws {Error} - Throws a user-friendly error message if the request fails.
   */
  async get<T>(path: string): Promise<T> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get<T>(path);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Performs a POST request to the specified path with the provided data.
   *
   * @param {string} path - The endpoint path.
   * @param {PostRequestInput} input - The request config.
   * @returns {Promise<T>} - The response data.
   * @throws {Error} - Throws a user-friendly error message if the request fails.
   */
  async post<T>(path: string, input: PostRequestInput): Promise<T> {
    const { data = {}, ignoreError = false } = input;

    try {
      const response: AxiosResponse = await this.axiosInstance.post<T>(
        path,
        data,
      );
      return response.data;
    } catch (error) {
      if (ignoreError && axios.isAxiosError(error)) {
        return error.response?.data as T;
      }
      throw this.handleError(error);
    }
  }

  /**
   * Performs a PUT request to the specified path with the provided data.
   *
   * @param {string} path - The endpoint path.
   * @param {PostRequestInput} input - The request config.
   * @returns {Promise<T>} - The response data.
   * @throws {Error} - Throws a user-friendly error message if the request fails.
   */
  async put<T>(path: string, input: PostRequestInput): Promise<T> {
    const { data = {}, ignoreError = false } = input;

    try {
      const response: AxiosResponse = await this.axiosInstance.put<T>(
        path,
        data,
      );
      return response.data;
    } catch (error) {
      if (ignoreError && axios.isAxiosError(error)) {
        return error.response?.data as T;
      }
      throw this.handleError(error);
    }
  }

  /**
   * Performs a DELETE request to the specified path.
   *
   * @param {string} path - The endpoint path.
   * @returns {Promise<T>} - The response data.
   * @throws {Error} - Throws a user-friendly error message if the request fails.
   */
  async delete<T>(path: string): Promise<T> {
    try {
      const response: AxiosResponse = await this.axiosInstance.delete<T>(path);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Handles errors from Axios requests, logging them and returning a user-friendly error message.
   *
   * @param {unknown} error - The error object from Axios.
   * @returns {Error} - A new error with a user-friendly message.
   */
  private handleError(error: any): Error {
    let code = ErrorCodes.GENERAL_ERROR;
    if (axios.isAxiosError(error)) {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            code = ErrorCodes.BAD_REQUEST;
            break;
          case 401:
            code = ErrorCodes.UNAUTHORIZED;
            break;
          case 403:
            code = ErrorCodes.FORBIDDEN;
            break;
          case 404:
            code = ErrorCodes.NOT_FOUND;
            break;
          case 409:
            code = ErrorCodes.CONFLICT;
            break;
          case 410:
            code = ErrorCodes.GONE;
            break;
          case 415:
            code = ErrorCodes.UNSUPPORTED_MEDIA_TYPE;
            break;
          case 429:
            code = ErrorCodes.TOO_MANY_REQUESTS;
            break;
          case 500:
            code = ErrorCodes.INTERNAL_SERVER_ERROR;
            break;
          case 503:
            code = ErrorCodes.SERVICE_UNAVAILABLE;
            break;
          default:
            code = ErrorCodes.SERVER_ERROR;
        }
      } else if (error.request) {
        code = ErrorCodes.NETWORK_ERROR;
      } else {
        code = ErrorCodes.CONFIG_ERROR;
      }
    }
    const userFriendlyMessage = getUserFriendlyMessage(code);
    this.logger.error('Error Code:', code, 'Message:', userFriendlyMessage);
    this.logger.error('Result Data:', error.response?.data);
    return new Error(userFriendlyMessage);
  }
}
