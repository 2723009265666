import { GraphqlEventChatRoomRepository } from './infrastructure';
import { EventChatRoomService } from './services';

export * from './domain';
export * from './infrastructure';
export * from './services';

const graphqlEventChatRoomRepository = new GraphqlEventChatRoomRepository();
const eventChatRoomService = new EventChatRoomService(
  graphqlEventChatRoomRepository,
);

export { eventChatRoomService };
