type ElementResult = {
  selector: string;
  element: HTMLElement | null;
};

type WaitForElementReturn = {
  promise: Promise<ElementResult>;
  cancel: () => void;
};

/**
 * Searches for elements matching the given selectors and returns the first match.
 * @param selectors - An array of CSS selectors to search for.
 * @returns The first element matching the selectors, or undefined if none match.
 */
const findElement = (selectors: string[]): ElementResult | undefined => {
  return selectors
    .map(selector => ({
      selector,
      element: document.querySelector<HTMLElement>(selector),
    }))
    .find(result => result.element !== null);
};

/**
 * Sets up a MutationObserver to watch for changes in the DOM and resolve when an element is found.
 * @param selectors - An array of CSS selectors to wait for.
 * @param resolve - The resolve function of the promise to resolve when an element is found.
 * @returns The MutationObserver instance.
 */
const startObservation = (
  selectors: string[],
  resolve: (value: ElementResult) => void,
): MutationObserver => {
  const observer = new MutationObserver(() => {
    const foundResult = findElement(selectors);
    if (foundResult) {
      resolve(foundResult);
    }
  });

  observer.observe(document.documentElement, {
    childList: true,
    subtree: true,
  });

  return observer;
};

/**
 * Waits for any of the provided DOM selectors to appear in the document.
 * Provides a cancel function to stop the search before completion. Supports optional timeout.
 * @param selectors - An array of selector strings to wait for.
 * @param timeout - Optional timeout in milliseconds after which the promise will resolve with null if the element is not found.
 * @returns An object containing a promise and a cancel function.
 */
export const waitForElement = (
  selectors: string[],
  timeout = 5000,
): WaitForElementReturn => {
  let observer: MutationObserver | null = null;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let resolve: (value: ElementResult) => void;

  const promise = new Promise<ElementResult>(r => {
    resolve = r;
  });

  const cleanUp = (): void => {
    if (observer) {
      observer.disconnect();
      observer = null;
    }
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  const immediateResult = findElement(selectors);

  if (immediateResult) {
    // @ts-expect-error "resolve" is defined at the beginning of the function
    resolve(immediateResult);
  } else {
    // @ts-expect-error "resolve" is defined at the beginning of the function
    observer = startObservation(selectors, resolve);
  }

  if (timeout !== undefined) {
    timeoutId = setTimeout(() => {
      resolve({ selector: '', element: null });
      cleanUp();
    }, timeout);
  }

  const cancel = (): void => {
    cleanUp();
    resolve({ selector: '', element: null });
  };

  return { promise, cancel };
};
