type Config = {
  url: string;
  token: string;
  onOpen: (event: Event) => void;
  onClose: (event: CloseEvent) => void;
  onMessage: (event: MessageEvent<any>) => void;
  onError: (event: Event) => void;
};

export const connectToSocket = (config: Config) => {
  const { url, token, onOpen, onClose, onMessage, onError } = config;

  const socket = new WebSocket(url, token);

  socket.onopen = event => onOpen(event);
  socket.onclose = event => onClose(event);
  socket.onerror = event => onError(event);
  socket.onmessage = event => onMessage(event);

  return socket;
};
