export enum NizzaVariationTypes {
  COMBO = 'Combo',
  BADGE = 'Badged',
}

export enum NizzaProductRequestOriginTypes {
  VTEX = 'VTEX',
  PLATFORM = 'platform',
  CACE = 'CACE',
  GLOBAL_PAGE = 'globalPage',
  CUSTOMIZATION = 'customization',
  FAST_STORE = 'faststore',
  UNKNOWN = 'unknown',
}
