import env from '~env';
import { ApiV1Response } from '../../../../types';
import { customFetch } from '../../../../utils';
import { PublicAccountRepository } from '../../domain';
import { RawPublicAccount } from '../dtos';
import { rawPublicAccountToDomain } from '../mappers';

const BASE_URL = `${env.apiUrls.restV1}/accounts/v2`;

export class RestPublicAccountRepository implements PublicAccountRepository {
  async get(name: string) {
    const url = `${BASE_URL}/${name}`;

    const { data } = await customFetch<ApiV1Response<RawPublicAccount>>({
      url,
    });

    return data ? rawPublicAccountToDomain(data) : null;
  }
}
