import { ApiGraphqlTypes as AGT } from '@nizza/core';
import { GraphQLClient } from 'graphql-request';
import env from '~env';
import { createCollection } from '../../utils';
import {
  Collection,
  CollectionInput,
  CollectionRepository,
  CollectionSearchInput,
  CollectionUpdateInput,
  ProductToSync,
} from '../domain';

const API_URL = env.apiUrls.graphql;

export class CollectionGraphQLRepository implements CollectionRepository {
  private client: GraphQLClient;
  account!: string;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async find(id: string): Promise<Collection | null> {
    const data = { account: this.account, id };

    const { findCollection } = await this.client.request<
      AGT.FindCollectionQuery,
      AGT.FindCollectionQueryVariables
    >(AGT.FindCollectionDocument, { data });

    this.propagateGraphqlErrorIfExists(findCollection);

    return createCollection(
      (findCollection as AGT.CollectionSuccess).data as AGT.Collection,
    );
  }

  async create(data: CollectionInput) {
    const { createCollection: collectionCreated } = await this.client.request<
      AGT.AddCollectionMutation,
      AGT.AddCollectionMutationVariables
    >(AGT.AddCollectionDocument, { data: data as AGT.CreateCollectionInput });

    this.propagateGraphqlErrorIfExists(collectionCreated as any);

    return createCollection({
      ...data,
      id: (collectionCreated as AGT.CollectionSuccess).data?.id!,
    });
  }

  async update(data: CollectionUpdateInput) {
    const { updateCollection } = await this.client.request<
      AGT.UpdateCollectionMutation,
      AGT.UpdateCollectionMutationVariables
    >(AGT.UpdateCollectionDocument, {
      data: data as AGT.UpdateCollectionInput,
    });

    this.propagateGraphqlErrorIfExists(updateCollection);
  }

  async delete(id: string) {
    const data = { account: this.account, id };

    const { deleteCollection } = await this.client.request<
      AGT.DeleteCollectionMutation,
      AGT.DeleteCollectionMutationVariables
    >(AGT.DeleteCollectionDocument, { data });

    this.propagateGraphqlErrorIfExists(deleteCollection);
  }

  async search(input: CollectionSearchInput = {}): Promise<Collection[]> {
    const data = { ...input, account: this.account };

    const { searchCollection } = await this.client.request<
      AGT.SearchCollectionsQuery,
      AGT.SearchCollectionsQueryVariables
    >(AGT.SearchCollectionsDocument, { data });

    this.propagateGraphqlErrorIfExists(searchCollection);

    return (searchCollection as AGT.CollectionSuccessArray).data?.map(
      createCollection,
    ) as Collection[];
  }

  async getAll(input: CollectionSearchInput): Promise<Collection[]> {
    const data = { ...input, account: this.account };

    const { getAllCollection } = await this.client.request<
      AGT.GetAllCollectionsQuery,
      AGT.GetAllCollectionsQueryVariables
    >(AGT.GetAllCollectionsDocument, { data });

    this.propagateGraphqlErrorIfExists(getAllCollection);

    return (getAllCollection as AGT.CollectionSuccessArray).data?.map(
      createCollection,
    ) as Collection[];
  }

  async manageProduct(data: ProductToSync) {
    const { manageCollectionProduct } = await this.client.request<
      AGT.ManageCollectionProductMutation,
      AGT.ManageCollectionProductMutationVariables
    >(AGT.ManageCollectionProductDocument, { data: data as any });

    this.propagateGraphqlErrorIfExists(manageCollectionProduct);

    return manageCollectionProduct.__typename === 'CollectionSuccess';
  }

  private propagateGraphqlErrorIfExists(
    res: AGT.CollectionResult | AGT.CollectionResultArray,
  ) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
