import {
  IAggregateRoot,
  createAggregateRoot,
} from '../../../../../clean-architecture';
import { EventChatRoomProps, RawEventChatRoom } from '../shared';

export class EventChatRoom implements IAggregateRoot, EventChatRoomProps {
  readonly root = createAggregateRoot<EventChatRoomProps, EventChatRoom>(this);

  token!: string;

  private constructor(props: EventChatRoomProps) {
    this.root.setProps(props);
  }

  static toDomain(raw: RawEventChatRoom): EventChatRoom {
    const data: EventChatRoomProps = {
      token: raw.token,
    };

    return new EventChatRoom(data);
  }

  toPrimitives() {
    const { root, ...rest } = this;

    return {
      ...rest,
    };
  }
}
