import env from '~env';
import { ApiV1Response } from '../../../../types';
import { customFetch } from '../../../../utils';
import { PrivateAccountRepository } from '../../domain';
import { RawPrivateAccount } from '../dtos';
import { rawPrivateAccountToDomain } from '../mappers';

const BASE_URL = `${env.apiUrls.restV1}/accounts/v2/pvt`;

export class RestPrivateAccountRepository implements PrivateAccountRepository {
  async get(name: string, authToken: string) {
    const url = `${BASE_URL}/${name}`;

    const { data } = await customFetch<ApiV1Response<RawPrivateAccount>>({
      url,
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });

    return data ? rawPrivateAccountToDomain(data) : null;
  }
}
