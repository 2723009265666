import { ComponentManager, GlobalComponent, shortId, sleep } from '@nizza/core';
import logger from '~logger';
import {
  ScriptConfig as BaseScriptConfig,
  ScriptEvents,
  ScriptInjector,
} from '../script-injector';

export interface VtexIoScriptConfig extends BaseScriptConfig {
  src: string;
  lazy?: boolean;
  id?: string;
  baseId?: string;
  globalId?: string;
}

type EventCallback = (instance: VtexIoScriptInjector, data?: any) => void;

export class VtexIoScriptInjector {
  private scriptInjector: ScriptInjector;
  private lazy: boolean;
  private id?: string;
  private baseId?: string;
  private globalId?: string;
  private events: Record<ScriptEvents, EventCallback[]>;
  component?: ComponentManager;
  fullId: string;

  constructor(config: VtexIoScriptConfig) {
    this.lazy = config.lazy ?? true;
    this.id = config.id;
    this.baseId = config.baseId;
    this.globalId = config.globalId;
    this.events = {
      loading: [],
      ready: [],
      error: [],
    };

    if (!this.id && !this.baseId) {
      throw new Error(
        'VtexIoScriptInjector: Either id or baseId must be provided in the config.',
      );
    }

    this.fullId = this.generateFullId();

    this.scriptInjector = new ScriptInjector(config.src, {
      module: config.module,
      cache: config.cache,
      attrs: config.attrs,
    });

    this.scriptInjector.on('loading', () => this.triggerEvent('loading'));
    this.scriptInjector.on('ready', async () => {
      await this.loadComponent();
      this.triggerEvent('ready');
    });
    this.scriptInjector.on('error', error => {
      this.triggerEvent('error', error);
      logger.error(`VtexIoScriptInjector: Script load error`, error);
    });

    if (!this.lazy) {
      this.execute();
    }
  }

  on(event: ScriptEvents, callback: EventCallback) {
    this.events[event]?.push(callback);
    return this;
  }

  execute() {
    this.scriptInjector.execute();
  }

  async render(props: Record<string, any> = {}) {
    if (this.component) {
      try {
        await this.component.render(props);
      } catch (error) {
        logger.error(
          'VtexIoScriptInjector: error render ComponentManager',
          error,
        );
      }
    } else {
      const errorMsg = `VtexIoScriptInjector: Component not loaded (id: ${this.fullId})`;
      logger.error(errorMsg);
    }
  }

  unmount() {
    if (this.component) {
      this.component.unmount();
    } else {
      const errorMsg = `VtexIoScriptInjector: Component not loaded (id: ${this.fullId})`;
      logger.error(errorMsg);
    }
  }

  getElement() {
    return document.getElementById(this.fullId);
  }

  async loadComponent() {
    try {
      this.component = await this.getGlobalComponent();
    } catch (error) {
      this.triggerEvent('error', error);
      logger.error(
        `VtexIoScriptInjector: Error loading component (id: ${this.fullId})`,
        error,
      );
    }
  }

  private async getGlobalComponent(): Promise<ComponentManager | undefined> {
    const baseId = this.fullId
      .split('__')[0]
      .replace(/^[a-z]{2}-/, '')
      .replace(/-./g, x => x[1].toUpperCase());
    const maxRetries = 20;
    const initialInterval = 50;

    for (let i = 0; i < maxRetries; i++) {
      const module = (window as any)[this.globalId!]?.[
        baseId
      ] as GlobalComponent;
      if (module) {
        return new ComponentManager(this.fullId, module.render, logger);
      }
      await sleep(initialInterval * 2 ** i);
    }

    const errorMsg = `VtexIoScriptInjector: Render function not found after ${maxRetries} attempts.`;
    logger.error(errorMsg);
    throw new Error(errorMsg);
  }

  private triggerEvent(event: ScriptEvents, data?: any) {
    const callbacks = this.events[event];
    if (!callbacks) return;
    callbacks.forEach(callback => callback(this, data));
  }

  private generateFullId(): string {
    if (this.baseId) {
      return `${this.baseId}__${shortId(6)}`;
    } else if (this.id) {
      return this.id;
    }
    const errorMsg =
      'VtexIoScriptInjector: Either id or baseId must be provided in the config.';
    logger.error(errorMsg);
    throw new Error(errorMsg);
  }
}
