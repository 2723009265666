type Store = Record<string, string>;

export class MemoryStorage implements Storage {
  private store: Store;

  constructor() {
    this.store = this.getStoreFromWindow();
  }

  private getStoreFromWindow() {
    let store: Store = {};

    try {
      store = JSON.parse(window.self.name);
    } catch (e) {
      return {};
    }

    if (store && typeof store === 'object') return store;
    return {};
  }

  private setStoreToWindow(data: Store) {
    const store = JSON.stringify(data);
    window.self.name = store;
  }

  setItem(key: string, value: string): void {
    this.store[key] = value;
    this.setStoreToWindow(this.store);
  }

  getItem(key: string): string | null {
    return this.store[key] ?? null;
  }

  removeItem(key: string): void {
    delete this.store[key];
    this.setStoreToWindow(this.store);
  }

  clear(): void {
    this.setStoreToWindow({});
  }

  key(index: number): string | null {
    const keys = Object.keys(this.store);
    return keys[index] ?? null;
  }

  get length() {
    return Object.keys(this.store).length;
  }
}
