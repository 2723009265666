import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type ActionSnackVideoSuccess = {
  __typename?: 'ActionSnackVideoSuccess';
  message: Scalars['String'];
};

export type AddToCartInput = {
  account: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  idEvent: Scalars['String'];
  isMobile: Scalars['Boolean'];
  orderFormId: Scalars['String'];
  productId: Scalars['String'];
  productImageUrl: Scalars['String'];
  productName: Scalars['String'];
  sectionFromWhereItWasAdded: Scalars['String'];
  sessionId: Scalars['String'];
};

export type AddToCartResult = AddToCartSuccess | ErrorGeneral;

export type AddToCartSuccess = {
  __typename?: 'AddToCartSuccess';
  message: Scalars['String'];
};

export type BlockUserInput = {
  account: Scalars['String'];
  idEvent: Scalars['String'];
  ip?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type BlockUserResult = BlockUserSuccess | ErrorGeneral;

export type BlockUserSuccess = {
  __typename?: 'BlockUserSuccess';
  message: Scalars['String'];
};

export type ChangeUsernameInput = {
  account: Scalars['String'];
  username: Scalars['String'];
};

export type ChangeUsernameResult = ChangeUsernameSuccess | ErrorGeneral;

export type ChangeUsernameSuccess = {
  __typename?: 'ChangeUsernameSuccess';
  message: Scalars['String'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  account: Scalars['String'];
  connectionId?: Maybe<Scalars['String']>;
  data: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idEvent: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  second?: Maybe<Scalars['Float']>;
  sendDate?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  urlGif?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type ChatMessageArray = {
  __typename?: 'ChatMessageArray';
  data?: Maybe<Array<ChatMessage>>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  id: Scalars['String'];
};

export type ChatRoomData = {
  __typename?: 'ChatRoomData';
  data?: Maybe<ChatRoom>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type ChatRoomToken = {
  __typename?: 'ChatRoomToken';
  sessionExpirationTime: Scalars['String'];
  token: Scalars['String'];
  tokenExpirationTime: Scalars['String'];
};

export type ChatRoomTokenData = {
  __typename?: 'ChatRoomTokenData';
  data?: Maybe<ChatRoomToken>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type Clip = {
  endTime: Scalars['String'];
  name: Scalars['String'];
  startTime: Scalars['String'];
};

export type Collection = {
  __typename?: 'Collection';
  account?: Maybe<Scalars['String']>;
  createdat?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Product>>;
  status?: Maybe<Status>;
};

export type CollectionResult = CollectionSuccess | ErrorGeneral;

export type CollectionResultArray = CollectionSuccessArray | ErrorGeneral;

export type CollectionSuccess = {
  __typename?: 'CollectionSuccess';
  data?: Maybe<Collection>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type CollectionSuccessArray = {
  __typename?: 'CollectionSuccessArray';
  data?: Maybe<Array<Collection>>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type CreateChatRoomInput = {
  account: Scalars['String'];
  idEvent: Scalars['String'];
};

export type CreateChatRoomResult = ChatRoomData | ErrorGeneral;

export type CreateCollectionInput = {
  account: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type CreatePlayListInput = {
  account: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  videos: Array<CreatePlayListVideoInput>;
};

export type CreatePlayListResult = CreatePlayListSuccess | ErrorGeneral;

export type CreatePlayListSuccess = {
  __typename?: 'CreatePlayListSuccess';
  message: Scalars['String'];
};

export type CreatePlayListVideoInput = {
  order: Scalars['Float'];
  snackVideoId: Scalars['String'];
};

export type CreateSnackVideoInput = {
  account: Scalars['String'];
  clips: Array<Clip>;
  idEvent: Scalars['String'];
};

export type CreateSnackVideoResult = ErrorGeneral | SnackVideoArray;

export type CustomPlayer = {
  __typename?: 'CustomPlayer';
  account: Scalars['String'];
  component: Scalars['String'];
  data: Scalars['JSON'];
  duration?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  section: Scalars['String'];
};

export type CustomPlayerInput = {
  account: Scalars['String'];
  data: Scalars['JSON'];
  id: Scalars['String'];
  section: Scalars['String'];
};

export type CustomPlayerInputAccount = {
  account: Scalars['String'];
};

export type CustomPlayerResult = CustomPlayerSuccess | ErrorGeneral;

export type CustomPlayerResultArray = CustomPlayerSuccessArray | ErrorGeneral;

export type CustomPlayerSuccess = {
  __typename?: 'CustomPlayerSuccess';
  data?: Maybe<CustomPlayer>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type CustomPlayerSuccessArray = {
  __typename?: 'CustomPlayerSuccessArray';
  data?: Maybe<Array<CustomPlayer>>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type DeleteChatInput = {
  account: Scalars['String'];
  id: Scalars['String'];
  idEvent: Scalars['String'];
  sendDate: Scalars['String'];
  username: Scalars['String'];
};

export type DeleteChatMessageResult = DeleteChatMessageSuccess | ErrorGeneral;

export type DeleteChatMessageSuccess = {
  __typename?: 'DeleteChatMessageSuccess';
  message: Scalars['String'];
};

export type DeleteSnackVideoInput = {
  account: Scalars['String'];
  id: Scalars['String'];
};

export type Error = {
  message: Scalars['String'];
  statusCode: Scalars['String'];
  timestamp: Scalars['String'];
};

export type ErrorGeneral = Error & {
  __typename?: 'ErrorGeneral';
  message: Scalars['String'];
  statusCode: Scalars['String'];
  timestamp: Scalars['String'];
};

export type EventArray = {
  __typename?: 'EventArray';
  data?: Maybe<Array<LiveStreamingEvent>>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type EventData = {
  __typename?: 'EventData';
  data?: Maybe<LiveStreamingEvent>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export enum EventStatus {
  Created = 'CREATED',
  Finalized = 'FINALIZED',
  Live = 'LIVE',
  Starting = 'STARTING',
}

export type FindByProductSnackVideoInput = {
  account: Scalars['String'];
  idProduct: Scalars['String'];
};

export type FindByProductSnackVideoResult = ErrorGeneral | SnackVideoArray;

export type FindEventInput = {
  account: Scalars['String'];
  id: Scalars['String'];
};

export type FindEventResult = ErrorGeneral | EventData;

export type FindManySnackVideoInput = {
  account: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type FindManySnackVideoResult = ErrorGeneral | SnackVideoArray;

export type GetAllChatMessageInput = {
  account: Scalars['String'];
  idEvent: Scalars['String'];
};

export type GetAllChatMessageResult = ChatMessageArray | ErrorGeneral;

export type GetChatRoomInput = {
  account: Scalars['String'];
  idEvent: Scalars['String'];
  userName: Scalars['String'];
};

export type GetChatRoomTokenResult = ChatRoomTokenData | ErrorGeneral;

export type GetPlayListData = ErrorGeneral | PlayListData;

export type GetPlayListQueryInput = {
  account: Scalars['String'];
  id: Scalars['String'];
};

export type GetPlayListResult = ErrorGeneral | PlayListArray;

export type GetTimeLineInput = {
  account: Scalars['String'];
  idEvent: Scalars['String'];
};

export type GetTimeLineResult = ErrorGeneral | TimeLineArray;

export type HighlightProductInput = {
  account: Scalars['String'];
  collection: Scalars['String'];
  idEvent: Scalars['String'];
  productIds?: Array<Scalars['String']>;
  showProduct: Scalars['Boolean'];
};

export type HighlightProductResult = ErrorGeneral | HighlightProductSuccess;

export type HighlightProductSuccess = {
  __typename?: 'HighlightProductSuccess';
  message: Scalars['String'];
};

export type LiveStreamingEvent = {
  __typename?: 'LiveStreamingEvent';
  account: Scalars['String'];
  createdAt: Scalars['String'];
  eventName: Scalars['String'];
  id: Scalars['String'];
  recordVideoPath?: Maybe<Scalars['String']>;
  recordVideoPathMp4?: Maybe<Scalars['String']>;
  status: EventStatus;
  thumbnails?: Maybe<Array<Scalars['String']>>;
  thumbnailsPath?: Maybe<Scalars['String']>;
};

export type ManageCollectionProductInInput = {
  account: Scalars['String'];
  id: Scalars['String'];
  method: Methods;
  productId: Scalars['String'];
};

export enum Methods {
  Add = 'add',
  Remove = 'remove',
}

export type Mutation = {
  __typename?: 'Mutation';
  appendProductsSnackVideo: RelateEntitySnackVideo;
  blockUser: BlockUserResult;
  changeAccountUsername: ChangeUsernameResult;
  createChatRoom: CreateChatRoomResult;
  createCollection: CollectionResult;
  createPlayList: CreatePlayListResult;
  createSnackVideos: CreateSnackVideoResult;
  deleteAllCustomPlayer: CustomPlayerResult;
  deleteChatMessage: DeleteChatMessageResult;
  deleteChatRoom: CreateChatRoomResult;
  deleteCollection: CollectionResult;
  deleteRelationshipSnackVideo: RelateEntitySnackVideo;
  deleteSnackVideo: RelateEntitySnackVideo;
  highlightProduct: HighlightProductResult;
  manageCollectionProduct: CollectionResult;
  pinChatMessage: PinChatMessageResult;
  runQuestionProcessor: RunQuestionProcessorResult;
  runVideoAnalyzer: RunVideoAnalyzerResult;
  saveChatMessage: SaveChatMessageResult;
  sendAddToCart: AddToCartResult;
  stopQuestionProcessor: RunQuestionProcessorResult;
  updateCollection: CollectionResult;
  updateCustomPlayer: CustomPlayerResult;
  updatePlayList: UpdatePlayListResult;
  updateProgressSnackVideo: UpdateProgressSnackVideosResult;
};

export type MutationAppendProductsSnackVideoArgs = {
  data: RelateToProductsSnackVideoInput;
};

export type MutationBlockUserArgs = {
  data: BlockUserInput;
};

export type MutationChangeAccountUsernameArgs = {
  data: ChangeUsernameInput;
};

export type MutationCreateChatRoomArgs = {
  data: CreateChatRoomInput;
};

export type MutationCreateCollectionArgs = {
  data: CreateCollectionInput;
};

export type MutationCreatePlayListArgs = {
  data: CreatePlayListInput;
};

export type MutationCreateSnackVideosArgs = {
  data: CreateSnackVideoInput;
};

export type MutationDeleteAllCustomPlayerArgs = {
  data: CustomPlayerInputAccount;
};

export type MutationDeleteChatMessageArgs = {
  data: DeleteChatInput;
};

export type MutationDeleteChatRoomArgs = {
  data: CreateChatRoomInput;
};

export type MutationDeleteCollectionArgs = {
  data: QueryInput;
};

export type MutationDeleteRelationshipSnackVideoArgs = {
  data: SnackVideoRelationshipKeys;
};

export type MutationDeleteSnackVideoArgs = {
  data: DeleteSnackVideoInput;
};

export type MutationHighlightProductArgs = {
  data: HighlightProductInput;
};

export type MutationManageCollectionProductArgs = {
  data: ManageCollectionProductInInput;
};

export type MutationPinChatMessageArgs = {
  data: PinChatInput;
};

export type MutationRunQuestionProcessorArgs = {
  data: RunQuestionProcessorInput;
};

export type MutationRunVideoAnalyzerArgs = {
  data: RunVideoAnalyzerInput;
};

export type MutationSaveChatMessageArgs = {
  data: SaveChatInput;
};

export type MutationSendAddToCartArgs = {
  data: AddToCartInput;
};

export type MutationStopQuestionProcessorArgs = {
  data: RunQuestionProcessorInput;
};

export type MutationUpdateCollectionArgs = {
  data: UpdateCollectionInput;
};

export type MutationUpdateCustomPlayerArgs = {
  data: CustomPlayerInput;
};

export type MutationUpdatePlayListArgs = {
  data: UpdatePlayListInput;
};

export type MutationUpdateProgressSnackVideoArgs = {
  data: UpdateProgressSnackVideosInput;
};

export type PinChatInput = {
  account: Scalars['String'];
  data: Scalars['String'];
  idEvent: Scalars['String'];
  pin: Scalars['Boolean'];
  sessionId: Scalars['String'];
  username: Scalars['String'];
};

export type PinChatMessageResult = ErrorGeneral | PinChatMessageSuccess;

export type PinChatMessageSuccess = {
  __typename?: 'PinChatMessageSuccess';
  message: Scalars['String'];
};

export type PlayList = {
  __typename?: 'PlayList';
  account: Scalars['String'];
  created: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  productAmount: Scalars['Float'];
  updated: Scalars['String'];
  videoAmount: Scalars['Float'];
  videos: Array<PlayListVideo>;
};

export type PlayListArray = {
  __typename?: 'PlayListArray';
  data?: Maybe<Array<PlayList>>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type PlayListData = {
  __typename?: 'PlayListData';
  data?: Maybe<PlayList>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type PlayListVideo = {
  __typename?: 'PlayListVideo';
  account: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idPlayList: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  productId?: Maybe<Scalars['String']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  transmitionType?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  account: Scalars['String'];
  createdat?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  pictures?: Maybe<Array<Scalars['String']>>;
  price: Scalars['Float'];
  salesprice?: Maybe<Scalars['Float']>;
  status?: Maybe<Status>;
  title: Scalars['String'];
};

export type ProductInfoInput = {
  id: Scalars['String'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  findByProductSnackVideo: FindByProductSnackVideoResult;
  findCollection: CollectionResult;
  findEvent: FindEventResult;
  findManySnackVideos: FindManySnackVideoResult;
  getAllChatMessage: GetAllChatMessageResult;
  getAllCollection: CollectionResultArray;
  getAllCustomPlayer: CustomPlayerResultArray;
  getAllSnackVideos: SnackVideoGetAll;
  getChatRoomToken: GetChatRoomTokenResult;
  getPlayList: GetPlayListData;
  getPlayLists: GetPlayListResult;
  getRelationshipSnackVideos: RelationshipSnackVideoArrayResponse;
  getTimeLine: GetTimeLineResult;
  searchCollection: CollectionResultArray;
  searchEvent: SearchEventResult;
  searchSnackVideos: SnackVideoGetAll;
};

export type QueryFindByProductSnackVideoArgs = {
  data: FindByProductSnackVideoInput;
};

export type QueryFindCollectionArgs = {
  data: QueryInput;
};

export type QueryFindEventArgs = {
  data: FindEventInput;
};

export type QueryFindManySnackVideosArgs = {
  data: FindManySnackVideoInput;
};

export type QueryGetAllChatMessageArgs = {
  data: GetAllChatMessageInput;
};

export type QueryGetAllCollectionArgs = {
  data: QueryInput;
};

export type QueryGetAllCustomPlayerArgs = {
  data: QueryInput;
};

export type QueryGetAllSnackVideosArgs = {
  data: QueryInput;
};

export type QueryGetChatRoomTokenArgs = {
  data: GetChatRoomInput;
};

export type QueryGetPlayListArgs = {
  data: GetPlayListQueryInput;
};

export type QueryGetPlayListsArgs = {
  data: QueryInput;
};

export type QueryGetRelationshipSnackVideosArgs = {
  data: SnackVideoRelationshipInput;
};

export type QueryGetTimeLineArgs = {
  data: GetTimeLineInput;
};

export type QuerySearchCollectionArgs = {
  data: QueryInput;
};

export type QuerySearchEventArgs = {
  data: SearchEventInput;
};

export type QuerySearchSnackVideosArgs = {
  data: SearchInput;
};

export type QueryInput = {
  account: Scalars['String'];
  cursor?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  includeTotal?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};

export type RelateEntitySnackVideo = ActionSnackVideoSuccess | ErrorGeneral;

export type RelateToProductsSnackVideoInput = {
  account: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Float'];
  productInfo: Array<ProductInfoInput>;
  productsId: Array<Scalars['String']>;
  snackVideoId: Scalars['String'];
};

export type RelationshipSnackVideo = {
  __typename?: 'RelationshipSnackVideo';
  attributeId: Scalars['String'];
  created: Scalars['String'];
  entity: Scalars['String'];
  modified: Scalars['String'];
  name: Scalars['String'];
  snackVideoId: Scalars['String'];
  sourceEntity: Scalars['String'];
  url: Scalars['String'];
};

export type RelationshipSnackVideoArray = {
  __typename?: 'RelationshipSnackVideoArray';
  data?: Maybe<Array<RelationshipSnackVideo>>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type RelationshipSnackVideoArrayResponse =
  | ErrorGeneral
  | RelationshipSnackVideoArray;

export type RunQuestionProcessorInput = {
  account: Scalars['String'];
  idEvent: Scalars['String'];
};

export type RunQuestionProcessorMessageSuccess = {
  __typename?: 'RunQuestionProcessorMessageSuccess';
  message: Scalars['String'];
};

export type RunQuestionProcessorResult =
  | ErrorGeneral
  | RunQuestionProcessorMessageSuccess;

export type RunVideoAnalyzerInput = {
  account: Scalars['String'];
  idEvent: Scalars['String'];
};

export type RunVideoAnalyzerMessageSuccess = {
  __typename?: 'RunVideoAnalyzerMessageSuccess';
  message: Scalars['String'];
};

export type RunVideoAnalyzerResult =
  | ErrorGeneral
  | RunVideoAnalyzerMessageSuccess;

export type SaveChatInput = {
  account: Scalars['String'];
  connectionId: Scalars['String'];
  data: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  idEvent: Scalars['String'];
  ip: Scalars['String'];
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  sendDate: Scalars['String'];
  sessionId: Scalars['String'];
  type: Scalars['String'];
  urlGif?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type SaveChatMessageResult = ErrorGeneral | SaveChatMessageSuccess;

export type SaveChatMessageSuccess = {
  __typename?: 'SaveChatMessageSuccess';
  message: Scalars['String'];
};

export type SearchEventInput = {
  account: Scalars['String'];
  cursor?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  includeTotal?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search: Scalars['String'];
  status?: InputMaybe<EventStatus>;
};

export type SearchEventResult = ErrorGeneral | EventArray;

export type SearchInput = {
  account: Scalars['String'];
  cursor?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  includeTotal?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search: Scalars['String'];
};

export type SnackVideo = {
  __typename?: 'SnackVideo';
  account: Scalars['String'];
  created?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  transmitionType?: Maybe<Scalars['String']>;
  uploadProgress: Scalars['Float'];
  url?: Maybe<Scalars['String']>;
};

export type SnackVideoArray = {
  __typename?: 'SnackVideoArray';
  data?: Maybe<Array<SnackVideo>>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type SnackVideoCreatedArray = {
  __typename?: 'SnackVideoCreatedArray';
  data?: Maybe<SnackVideoCreatedWithCursor>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type SnackVideoCreatedWithCursor = {
  __typename?: 'SnackVideoCreatedWithCursor';
  cursor?: Maybe<Scalars['JSON']>;
  snackVideos?: Maybe<Array<SnackVideosCreated>>;
  totalItems?: Maybe<Scalars['Float']>;
};

export type SnackVideoGetAll = ErrorGeneral | SnackVideoCreatedArray;

export type SnackVideoRelationshipInput = {
  account: Scalars['String'];
  snackVideoId: Scalars['String'];
};

export type SnackVideoRelationshipKeys = {
  account: Scalars['String'];
  attributeId: Scalars['String'];
  snackVideoId: Scalars['String'];
  sourceEntity: Scalars['String'];
};

export type SnackVideosCreated = {
  __typename?: 'SnackVideosCreated';
  account: Scalars['String'];
  created: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  entity: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  modified: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  transmitionType?: Maybe<Scalars['String']>;
  uploadProgress: Scalars['Float'];
  url?: Maybe<Scalars['String']>;
};

export enum Status {
  Active = 'active',
  Inactive = 'inactive',
}

export type TimeLine = {
  __typename?: 'TimeLine';
  comments: Scalars['Float'];
  hour: Scalars['Float'];
  likes: Scalars['Float'];
  minute: Scalars['Float'];
  orders: Scalars['Float'];
  time: Scalars['String'];
  viewers: Scalars['Float'];
};

export type TimeLineArray = {
  __typename?: 'TimeLineArray';
  data?: Maybe<Array<TimeLine>>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type UpdateCollectionInput = {
  account: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type UpdatePlayListInput = {
  account: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Array<CreatePlayListVideoInput>>;
};

export type UpdatePlayListResult = ErrorGeneral | UpdatePlayListSuccess;

export type UpdatePlayListSuccess = {
  __typename?: 'UpdatePlayListSuccess';
  message: Scalars['String'];
};

export type UpdateProgressSnackVideos = {
  __typename?: 'UpdateProgressSnackVideos';
  account: Scalars['String'];
  id: Scalars['String'];
  progress: Scalars['Float'];
};

export type UpdateProgressSnackVideosData = {
  __typename?: 'UpdateProgressSnackVideosData';
  data?: Maybe<UpdateProgressSnackVideos>;
  duration?: Maybe<Scalars['String']>;
  isArray?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type UpdateProgressSnackVideosInput = {
  account: Scalars['String'];
  id: Scalars['String'];
  progress: Scalars['Float'];
};

export type UpdateProgressSnackVideosResult =
  | ErrorGeneral
  | UpdateProgressSnackVideosData;

export type AddCollectionMutationVariables = Exact<{
  data: CreateCollectionInput;
}>;

export type AddCollectionMutation = {
  __typename?: 'Mutation';
  createCollection:
    | {
        __typename: 'CollectionSuccess';
        data?: { __typename?: 'Collection'; id: string } | null;
      }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type DeleteCollectionMutationVariables = Exact<{
  data: QueryInput;
}>;

export type DeleteCollectionMutation = {
  __typename?: 'Mutation';
  deleteCollection:
    | { __typename: 'CollectionSuccess' }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type FindCollectionQueryVariables = Exact<{
  data: QueryInput;
}>;

export type FindCollectionQuery = {
  __typename?: 'Query';
  findCollection:
    | {
        __typename: 'CollectionSuccess';
        data?: {
          __typename?: 'Collection';
          id: string;
          account?: string | null;
          name: string;
          description?: string | null;
          status?: Status | null;
          createdat?: any | null;
          products?: Array<{
            __typename?: 'Product';
            id: string;
            account: string;
            title: string;
            link?: string | null;
            pictures?: Array<string> | null;
            price: number;
            salesprice?: number | null;
            status?: Status | null;
            createdat?: string | null;
          }> | null;
        } | null;
      }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type GetAllCollectionsQueryVariables = Exact<{
  data: QueryInput;
}>;

export type GetAllCollectionsQuery = {
  __typename?: 'Query';
  getAllCollection:
    | {
        __typename: 'CollectionSuccessArray';
        data?: Array<{
          __typename?: 'Collection';
          id: string;
          account?: string | null;
          name: string;
          description?: string | null;
          status?: Status | null;
          createdat?: any | null;
          products?: Array<{
            __typename?: 'Product';
            id: string;
            account: string;
            title: string;
            link?: string | null;
            pictures?: Array<string> | null;
            price: number;
            salesprice?: number | null;
            status?: Status | null;
            createdat?: string | null;
          }> | null;
        }> | null;
      }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type ManageCollectionProductMutationVariables = Exact<{
  data: ManageCollectionProductInInput;
}>;

export type ManageCollectionProductMutation = {
  __typename?: 'Mutation';
  manageCollectionProduct:
    | { __typename: 'CollectionSuccess' }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type SearchCollectionsQueryVariables = Exact<{
  data: QueryInput;
}>;

export type SearchCollectionsQuery = {
  __typename?: 'Query';
  searchCollection:
    | {
        __typename: 'CollectionSuccessArray';
        data?: Array<{
          __typename?: 'Collection';
          id: string;
          account?: string | null;
          name: string;
          description?: string | null;
          status?: Status | null;
          createdat?: any | null;
          products?: Array<{
            __typename?: 'Product';
            id: string;
            account: string;
            title: string;
            link?: string | null;
            pictures?: Array<string> | null;
            price: number;
            salesprice?: number | null;
            status?: Status | null;
            createdat?: string | null;
          }> | null;
        }> | null;
      }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type UpdateCollectionMutationVariables = Exact<{
  data: UpdateCollectionInput;
}>;

export type UpdateCollectionMutation = {
  __typename?: 'Mutation';
  updateCollection:
    | { __typename: 'CollectionSuccess' }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type FindMinimalEventQueryVariables = Exact<{
  data: FindEventInput;
}>;

export type FindMinimalEventQuery = {
  __typename?: 'Query';
  findEvent:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'EventData';
        data?: {
          __typename?: 'LiveStreamingEvent';
          id: string;
          account: string;
          eventName: string;
          thumbnailsPath?: string | null;
          recordVideoPath?: string | null;
          createdAt: string;
          status: EventStatus;
          thumbnails?: Array<string> | null;
        } | null;
      };
};

export type SearchMinimalEventsQueryVariables = Exact<{
  data: SearchEventInput;
}>;

export type SearchMinimalEventsQuery = {
  __typename?: 'Query';
  searchEvent:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'EventArray';
        data?: Array<{
          __typename?: 'LiveStreamingEvent';
          id: string;
          account: string;
          eventName: string;
          thumbnailsPath?: string | null;
          recordVideoPath?: string | null;
          createdAt: string;
          status: EventStatus;
        }> | null;
      };
};

export type BlockUserMutationVariables = Exact<{
  data: BlockUserInput;
}>;

export type BlockUserMutation = {
  __typename?: 'Mutation';
  blockUser:
    | { __typename: 'BlockUserSuccess'; message: string }
    | { __typename: 'ErrorGeneral'; message: string };
};

export type ChangeAccountUsernameMutationVariables = Exact<{
  data: ChangeUsernameInput;
}>;

export type ChangeAccountUsernameMutation = {
  __typename?: 'Mutation';
  changeAccountUsername:
    | { __typename: 'ChangeUsernameSuccess'; message: string }
    | { __typename: 'ErrorGeneral'; message: string };
};

export type DeleteChatMessageMutationVariables = Exact<{
  data: DeleteChatInput;
}>;

export type DeleteChatMessageMutation = {
  __typename?: 'Mutation';
  deleteChatMessage:
    | { __typename: 'DeleteChatMessageSuccess'; message: string }
    | { __typename: 'ErrorGeneral'; message: string };
};

export type GetAllChatMessageQueryVariables = Exact<{
  data: GetAllChatMessageInput;
}>;

export type GetAllChatMessageQuery = {
  __typename?: 'Query';
  getAllChatMessage:
    | {
        __typename: 'ChatMessageArray';
        data?: Array<{
          __typename?: 'ChatMessage';
          account: string;
          connectionId?: string | null;
          data: string;
          email?: string | null;
          id: string;
          idEvent: string;
          sendDate?: string | null;
          sessionId?: string | null;
          type?: string | null;
          username?: string | null;
          isAdmin?: boolean | null;
          urlGif?: string | null;
          second?: number | null;
          ip?: string | null;
        }> | null;
      }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type GetChatRoomTokenQueryVariables = Exact<{
  data: GetChatRoomInput;
}>;

export type GetChatRoomTokenQuery = {
  __typename?: 'Query';
  getChatRoomToken:
    | {
        __typename: 'ChatRoomTokenData';
        data?: { __typename?: 'ChatRoomToken'; token: string } | null;
      }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type PinChatMessageMutationVariables = Exact<{
  data: PinChatInput;
}>;

export type PinChatMessageMutation = {
  __typename?: 'Mutation';
  pinChatMessage:
    | { __typename: 'ErrorGeneral'; message: string }
    | { __typename: 'PinChatMessageSuccess'; message: string };
};

export type HighlightProductMutationVariables = Exact<{
  data: HighlightProductInput;
}>;

export type HighlightProductMutation = {
  __typename?: 'Mutation';
  highlightProduct:
    | { __typename: 'ErrorGeneral'; message: string }
    | { __typename: 'HighlightProductSuccess'; message: string };
};

export type DeleteAllCustomizationMutationVariables = Exact<{
  data: CustomPlayerInputAccount;
}>;

export type DeleteAllCustomizationMutation = {
  __typename?: 'Mutation';
  deleteAllCustomPlayer:
    | { __typename: 'CustomPlayerSuccess' }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type GetAllCustomizationQueryVariables = Exact<{
  data: QueryInput;
}>;

export type GetAllCustomizationQuery = {
  __typename?: 'Query';
  getAllCustomPlayer:
    | {
        __typename: 'CustomPlayerSuccessArray';
        data?: Array<{
          __typename?: 'CustomPlayer';
          account: string;
          data: any;
          section: string;
          id: string;
        }> | null;
      }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type UpdateCustomizationMutationVariables = Exact<{
  data: CustomPlayerInput;
}>;

export type UpdateCustomizationMutation = {
  __typename?: 'Mutation';
  updateCustomPlayer:
    | { __typename: 'CustomPlayerSuccess' }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type SendAddToCartMutationVariables = Exact<{
  data: AddToCartInput;
}>;

export type SendAddToCartMutation = {
  __typename?: 'Mutation';
  sendAddToCart:
    | { __typename: 'AddToCartSuccess'; message: string }
    | { __typename: 'ErrorGeneral'; message: string };
};

export type GetTimeLineQueryVariables = Exact<{
  data: GetTimeLineInput;
}>;

export type GetTimeLineQuery = {
  __typename?: 'Query';
  getTimeLine:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'TimeLineArray';
        data?: Array<{
          __typename?: 'TimeLine';
          time: string;
          likes: number;
          comments: number;
          viewers: number;
          orders: number;
          minute: number;
          hour: number;
        }> | null;
      };
};

export type AppendProductsToSnackVideoMutationVariables = Exact<{
  data: RelateToProductsSnackVideoInput;
}>;

export type AppendProductsToSnackVideoMutation = {
  __typename?: 'Mutation';
  appendProductsSnackVideo:
    | { __typename: 'ActionSnackVideoSuccess'; message: string }
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      };
};

export type CreatePlayListMutationVariables = Exact<{
  data: CreatePlayListInput;
}>;

export type CreatePlayListMutation = {
  __typename?: 'Mutation';
  createPlayList:
    | { __typename: 'CreatePlayListSuccess'; message: string }
    | { __typename: 'ErrorGeneral'; message: string };
};

export type CreateSnackVideoBatchMutationVariables = Exact<{
  data: CreateSnackVideoInput;
}>;

export type CreateSnackVideoBatchMutation = {
  __typename?: 'Mutation';
  createSnackVideos:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'SnackVideoArray';
        data?: Array<{ __typename?: 'SnackVideo'; id: string }> | null;
      };
};

export type DeleteRelationshipWithSnackVideoMutationVariables = Exact<{
  data: SnackVideoRelationshipKeys;
}>;

export type DeleteRelationshipWithSnackVideoMutation = {
  __typename?: 'Mutation';
  deleteRelationshipSnackVideo:
    | { __typename: 'ActionSnackVideoSuccess'; message: string }
    | { __typename: 'ErrorGeneral'; message: string; statusCode: string };
};

export type DeleteSnackVideoMutationVariables = Exact<{
  data: DeleteSnackVideoInput;
}>;

export type DeleteSnackVideoMutation = {
  __typename?: 'Mutation';
  deleteSnackVideo:
    | { __typename: 'ActionSnackVideoSuccess'; message: string }
    | { __typename: 'ErrorGeneral'; message: string };
};

export type FindManySnackVideosQueryVariables = Exact<{
  data: FindManySnackVideoInput;
}>;

export type FindManySnackVideosQuery = {
  __typename?: 'Query';
  findManySnackVideos:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'SnackVideoArray';
        data?: Array<{
          __typename?: 'SnackVideo';
          id: string;
          name?: string | null;
          url?: string | null;
          thumbnailUrl?: string | null;
          uploadProgress: number;
          duration?: number | null;
          eventId?: string | null;
          eventName?: string | null;
          transmitionType?: string | null;
        }> | null;
      };
};

export type GetAllSnackVideosQueryVariables = Exact<{
  data: QueryInput;
}>;

export type GetAllSnackVideosQuery = {
  __typename?: 'Query';
  getAllSnackVideos:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'SnackVideoCreatedArray';
        data?: {
          __typename?: 'SnackVideoCreatedWithCursor';
          cursor?: any | null;
          totalItems?: number | null;
          snackVideos?: Array<{
            __typename?: 'SnackVideosCreated';
            id: string;
            name?: string | null;
            url?: string | null;
            thumbnailUrl?: string | null;
            uploadProgress: number;
            duration?: number | null;
            eventId?: string | null;
            eventName?: string | null;
            created: string;
            transmitionType?: string | null;
          }> | null;
        } | null;
      };
};

export type GetPlayListsQueryVariables = Exact<{
  data: QueryInput;
}>;

export type GetPlayListsQuery = {
  __typename?: 'Query';
  getPlayLists:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'PlayListArray';
        data?: Array<{
          __typename?: 'PlayList';
          id: string;
          name: string;
          description: string;
          created: string;
          videoAmount: number;
          productAmount: number;
          updated: string;
          videos: Array<{
            __typename?: 'PlayListVideo';
            id: string;
            thumbnailUrl?: string | null;
            name?: string | null;
            order: number;
            duration?: number | null;
            transmitionType?: string | null;
          }>;
        }> | null;
      };
};

export type GetSnackVideoRelationshipsQueryVariables = Exact<{
  data: SnackVideoRelationshipInput;
}>;

export type GetSnackVideoRelationshipsQuery = {
  __typename?: 'Query';
  getRelationshipSnackVideos:
    | { __typename: 'ErrorGeneral' }
    | {
        __typename: 'RelationshipSnackVideoArray';
        data?: Array<{
          __typename?: 'RelationshipSnackVideo';
          sourceEntity: string;
          attributeId: string;
        }> | null;
      };
};

export type SearchSnackVideosQueryVariables = Exact<{
  data: SearchInput;
}>;

export type SearchSnackVideosQuery = {
  __typename?: 'Query';
  searchSnackVideos:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'SnackVideoCreatedArray';
        data?: {
          __typename?: 'SnackVideoCreatedWithCursor';
          cursor?: any | null;
          totalItems?: number | null;
          snackVideos?: Array<{
            __typename?: 'SnackVideosCreated';
            id: string;
            name?: string | null;
            url?: string | null;
            thumbnailUrl?: string | null;
            uploadProgress: number;
            duration?: number | null;
            eventId?: string | null;
            eventName?: string | null;
            created: string;
          }> | null;
        } | null;
      };
};

export type UpdatePlayListMutationVariables = Exact<{
  data: UpdatePlayListInput;
}>;

export type UpdatePlayListMutation = {
  __typename?: 'Mutation';
  updatePlayList:
    | { __typename: 'ErrorGeneral'; message: string }
    | { __typename: 'UpdatePlayListSuccess'; message: string };
};

export type FindByProductSnackVideoQueryVariables = Exact<{
  data: FindByProductSnackVideoInput;
}>;

export type FindByProductSnackVideoQuery = {
  __typename?: 'Query';
  findByProductSnackVideo:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'SnackVideoArray';
        data?: Array<{
          __typename?: 'SnackVideo';
          id: string;
          name?: string | null;
          url?: string | null;
          thumbnailUrl?: string | null;
          duration?: number | null;
          transmitionType?: string | null;
        }> | null;
      };
};

export type GetPlayListQueryVariables = Exact<{
  data: GetPlayListQueryInput;
}>;

export type GetPlayListQuery = {
  __typename?: 'Query';
  getPlayList:
    | {
        __typename: 'ErrorGeneral';
        message: string;
        statusCode: string;
        timestamp: string;
      }
    | {
        __typename: 'PlayListData';
        data?: {
          __typename?: 'PlayList';
          id: string;
          description: string;
          videos: Array<{
            __typename?: 'PlayListVideo';
            productId?: string | null;
            id: string;
            name?: string | null;
            url?: string | null;
            thumbnailUrl?: string | null;
            duration?: number | null;
            transmitionType?: string | null;
            order: number;
            productName?: string | null;
            productImageUrl?: string | null;
          }>;
        } | null;
      };
};

export const AddCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCollectionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddCollectionMutation,
  AddCollectionMutationVariables
>;
export const DeleteCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables
>;
export const FindCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'products' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pictures' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesprice' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdat' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindCollectionQuery, FindCollectionQueryVariables>;
export const GetAllCollectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllCollections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionSuccessArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'products' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pictures' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesprice' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdat' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllCollectionsQuery,
  GetAllCollectionsQueryVariables
>;
export const ManageCollectionProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageCollectionProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ManageCollectionProductInInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manageCollectionProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManageCollectionProductMutation,
  ManageCollectionProductMutationVariables
>;
export const SearchCollectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchCollections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CollectionSuccessArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'products' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pictures' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesprice' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdat' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchCollectionsQuery,
  SearchCollectionsQueryVariables
>;
export const UpdateCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCollectionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;
export const FindMinimalEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindMinimalEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FindEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EventData' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eventName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnailsPath' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'recordVideoPath' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnails' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindMinimalEventQuery,
  FindMinimalEventQueryVariables
>;
export const SearchMinimalEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchMinimalEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SearchEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EventArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eventName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnailsPath' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'recordVideoPath' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchMinimalEventsQuery,
  SearchMinimalEventsQueryVariables
>;
export const BlockUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BlockUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BlockUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BlockUserSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockUserMutation, BlockUserMutationVariables>;
export const ChangeAccountUsernameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeAccountUsername' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChangeUsernameInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeAccountUsername' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChangeUsernameSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeAccountUsernameMutation,
  ChangeAccountUsernameMutationVariables
>;
export const DeleteChatMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteChatMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteChatInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteChatMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteChatMessageSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChatMessageMutation,
  DeleteChatMessageMutationVariables
>;
export const GetAllChatMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllChatMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetAllChatMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllChatMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChatMessageArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'connectionId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'idEvent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sendDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sessionId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isAdmin' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urlGif' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'second' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ip' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllChatMessageQuery,
  GetAllChatMessageQueryVariables
>;
export const GetChatRoomTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChatRoomToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetChatRoomInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getChatRoomToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChatRoomTokenData' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'token' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChatRoomTokenQuery,
  GetChatRoomTokenQueryVariables
>;
export const PinChatMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PinChatMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PinChatInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinChatMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PinChatMessageSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PinChatMessageMutation,
  PinChatMessageMutationVariables
>;
export const HighlightProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'highlightProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HighlightProductInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highlightProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'HighlightProductSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HighlightProductMutation,
  HighlightProductMutationVariables
>;
export const DeleteAllCustomizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAllCustomization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomPlayerInputAccount' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAllCustomPlayer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAllCustomizationMutation,
  DeleteAllCustomizationMutationVariables
>;
export const GetAllCustomizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllCustomization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllCustomPlayer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomPlayerSuccessArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'section' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllCustomizationQuery,
  GetAllCustomizationQueryVariables
>;
export const UpdateCustomizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCustomization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomPlayerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomPlayer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomizationMutation,
  UpdateCustomizationMutationVariables
>;
export const SendAddToCartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendAddToCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddToCartInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAddToCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AddToCartSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendAddToCartMutation,
  SendAddToCartMutationVariables
>;
export const GetTimeLineDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTimeLine' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetTimeLineInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTimeLine' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TimeLineArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'likes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'viewers' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orders' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minute' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hour' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTimeLineQuery, GetTimeLineQueryVariables>;
export const AppendProductsToSnackVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AppendProductsToSnackVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RelateToProductsSnackVideoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appendProductsSnackVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ActionSnackVideoSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AppendProductsToSnackVideoMutation,
  AppendProductsToSnackVideoMutationVariables
>;
export const CreatePlayListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePlayList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePlayListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlayList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreatePlayListSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePlayListMutation,
  CreatePlayListMutationVariables
>;
export const CreateSnackVideoBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSnackVideoBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSnackVideoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSnackVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SnackVideoArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSnackVideoBatchMutation,
  CreateSnackVideoBatchMutationVariables
>;
export const DeleteRelationshipWithSnackVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRelationshipWithSnackVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SnackVideoRelationshipKeys' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRelationshipSnackVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ActionSnackVideoSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRelationshipWithSnackVideoMutation,
  DeleteRelationshipWithSnackVideoMutationVariables
>;
export const DeleteSnackVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSnackVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteSnackVideoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSnackVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ActionSnackVideoSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSnackVideoMutation,
  DeleteSnackVideoMutationVariables
>;
export const FindManySnackVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindManySnackVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FindManySnackVideoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findManySnackVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SnackVideoArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnailUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uploadProgress' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'duration' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eventId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eventName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transmitionType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindManySnackVideosQuery,
  FindManySnackVideosQueryVariables
>;
export const GetAllSnackVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllSnackVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllSnackVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SnackVideoCreatedArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'snackVideos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'thumbnailUrl',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'uploadProgress',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'eventId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'eventName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'transmitionType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllSnackVideosQuery,
  GetAllSnackVideosQueryVariables
>;
export const GetPlayListsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlayLists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPlayLists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PlayListArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoAmount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productAmount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'thumbnailUrl',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'order' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'transmitionType',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPlayListsQuery, GetPlayListsQueryVariables>;
export const GetSnackVideoRelationshipsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSnackVideoRelationships' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SnackVideoRelationshipInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRelationshipSnackVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'RelationshipSnackVideoArray',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sourceEntity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributeId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSnackVideoRelationshipsQuery,
  GetSnackVideoRelationshipsQueryVariables
>;
export const SearchSnackVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchSnackVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SearchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchSnackVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SnackVideoCreatedArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'snackVideos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'thumbnailUrl',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'uploadProgress',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'eventId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'eventName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchSnackVideosQuery,
  SearchSnackVideosQueryVariables
>;
export const UpdatePlayListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePlayList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePlayListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePlayList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdatePlayListSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePlayListMutation,
  UpdatePlayListMutationVariables
>;
export const FindByProductSnackVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindByProductSnackVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FindByProductSnackVideoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findByProductSnackVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SnackVideoArray' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnailUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'duration' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transmitionType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindByProductSnackVideoQuery,
  FindByProductSnackVideoQueryVariables
>;
export const GetPlayListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlayList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetPlayListQueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPlayList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PlayListData' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'productId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'thumbnailUrl',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'transmitionType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'order' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productImageUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ErrorGeneral' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPlayListQuery, GetPlayListQueryVariables>;
