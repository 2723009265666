import { DomainError } from './DomainError';

export class UnexpectedError extends DomainError {
  constructor(error: Error | unknown) {
    super(
      'APP_UNEXPECTED_ERROR',
      (error as Error).message ?? `An unexpected error occurred.`,
      error,
    );
  }
}

export class AccountInvalidError extends DomainError {
  constructor() {
    super('APP_ACCOUNT_INVALID_ERROR', `Account not defined`);
  }
}

export class InvalidNizzaGlobalObjectError extends DomainError {
  constructor(prop: string) {
    super(
      'INVALID_NIZZA_GLOBAL_OBJECT_ERROR',
      `
      Configuration property invalid: "${prop}".

      It looks like you are trying to use a configuration property that is required but is "undefined", make sure you have initialized the global object "nizza".

      * If you are using the @nizza/core library:

        import { getNizza } from '@nizza/core';

        const nizza = getNizza();

        nizza.configure({
          account: env.account,
          ...
        });

      * If you are using the window:

        window.nizza.configure({
          account: env.account,
          ...
        });
    `,
    );
  }
}
