// import { Product } from '../../products';
import { Collection } from '../core';

// const product = (id: string) =>
//   Product.create({
//     id,
//     account: 'plataforma',
//     link: 'https://articulo.mercadolibre.com.co/MCO-889159831-lapiz-original-reemplazo-spen-samsung-tab-s7-s7-s7-fe-_JM#reco_item_pos=2&reco_backend=machinalis-homes&reco_backend_type=function&reco_client=home_navigation-recommendations&reco_id=d7c2423f-4c10-45a8-b8eb-77689f50773a&c_id=/home/navigation-recommendations/element&c_element_order=3&c_uid=68ac9893-d1c7-469c-93bc-9908b12a86f3',
//     pictures: [
//       'https://http2.mlstatic.com/D_NQ_NP_800157-MCO49844203235_052022-O.webp',
//       'https://http2.mlstatic.com/D_NQ_NP_650236-MCO49844190316_052022-O.webp',
//     ],
//     price: 2000,
//     salesPrice: 3000,
//     title: 'Lapiz Original Reemplazo Spen Samsung Tab S7, S7 +, S7 Fe',
//     createdAt: '15:37:34',
//   });

// FIXME: Ocurre el siguiente error
// Uncaught ReferenceError: Cannot access 'Collection' before initialization

export const collectionList: Collection[] = [
  // createCollection({
  //   account: 'plataforma',
  //   id: 'c1',
  //   name: 'Collection 1',
  //   products: [product('c1p1')],
  // }),
  // createCollection({
  //   account: 'plataforma',
  //   id: 'c2',
  //   name: 'Collection 2',
  //   products: [product('c2p1')],
  // }),
  // createCollection({
  //   account: 'plataforma',
  //   id: 'c3',
  //   name: 'Collection 3',
  //   products: [product('c3p1')],
  // }),
  // createCollection({
  //   account: 'plataforma',
  //   id: 'c4',
  //   name: 'Collection 4',
  //   products: [product('c4p1')],
  // }),
];
