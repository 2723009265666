import { LitElement, css, html } from 'lit';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import React from 'react';
import * as ReactDOM from 'react-dom/client';

type Styles = Record<string, unknown>;

/**
 * Abstract base class for LitElement components that host a React component.
 *
 * This class manages the integration of a React component within a LitElement,
 * including rendering, property updates, and hot module replacement (HMR) for styles.
 *
 * @template P The type of props expected by the hosted React component.
 */
export abstract class ReactHostElement<
  P = Record<string, unknown>,
> extends LitElement {
  static baseStyles = css`
    :host {
      width: 100%;
    }
  `;

  private rootRef: Ref<HTMLDivElement> = createRef();
  private rootInstance?: ReactDOM.Root;

  /**
   * Returns the React component to be hosted.
   *
   * @abstract
   * @returns {React.FC<P>} The React component class.
   */
  protected abstract getReactComponent(): React.FC<P>;

  /**
   * Returns the props to be passed to the React component.
   *
   * @abstract
   * @returns {P} The props object.
   */
  protected abstract getReactProps(): P;

  /**
   * Renders the LitElement template, including a div for hosting the React component.
   *
   * @returns {TemplateResult} The LitElement template.
   */
  protected render() {
    return html`<div ${ref(this.rootRef)}></div>`;
  }

  /**
   * Called when the LitElement is updated. Triggers the React component update.
   */
  protected updated() {
    this.updateReactComponent();
  }

  /**
   * Updates or initializes the React component inside the LitElement.
   *
   * @private
   */
  private updateReactComponent() {
    if (!this.rootRef.value) return;

    if (!this.rootInstance) {
      this.rootInstance = ReactDOM.createRoot(this.rootRef.value);
    }

    const reactElement = React.createElement(
      this.getReactComponent() as any,
      this.getReactProps() as any,
      React.createElement('slot'),
    );
    this.rootInstance.render(reactElement);
  }

  /**
   * Handles hot module replacement (HMR) for styles.
   *
   * @param {Styles} styles - The styles to be injected.
   */
  protected handleHMR(styles: Styles) {
    if (import.meta.hot) {
      this.injectStyles(styles);
      this.setupHMRListener();
    }
  }

  /**
   * Injects the initial styles into the Shadow DOM.
   *
   * @private
   * @param {Styles} styles - The styles to be injected.
   */
  private injectStyles(styles: Styles) {
    const getId = (str: string) => str.replace('./', '');

    for (const [key, value] of Object.entries(styles)) {
      const id = getId(key);
      const style = document.createElement('style');
      style.setAttribute('type', 'text/css');
      style.setAttribute('id', id);
      style.innerHTML = value as string;
      setTimeout(() => this.shadowRoot?.appendChild(style));
    }
  }

  /**
   * Sets up the listener for hot module replacement (HMR) updates.
   *
   * @private
   */
  private setupHMRListener() {
    import.meta?.hot?.on('inject-css-into-shadow-dom:hot-updated', data => {
      const style = this.shadowRoot?.getElementById(data.id);
      if (!style) return;
      style.innerHTML = data.content;
    });
  }
}
