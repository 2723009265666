import { Product } from '../../../products';

export const enum CollectionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

interface BaseProps {
  account: string;
  name: string;
}

export interface CollectionProps extends BaseProps {
  id: string;
  description: string;
  products: Product[];
  status: CollectionStatus;
  createdAt: Date;
}

export type CollectionInput = Partial<CollectionProps> &
  BaseProps & {
    createdAt?: string;
  };

export type CollectionUpdateInput = Partial<
  Omit<CollectionInput, 'createdAt'>
> & {
  id: string;
  account: string;
  products?: Product[];
};

export type CollectionSearchInput = {
  search?: string;
  page?: number;
  limit?: number;
};

export type ProductToSync = {
  id: string;
  account: string;
  productId: string;
  method: 'add' | 'remove';
};

export type ProductListToSync = {
  toAdd: ProductToSync[];
  toRemove: ProductToSync[];
};
