import { RuntimeType, VtexIOMetadata } from '../../types';
import { RuntimeStrategy, RuntimeStrategyConfig } from '../types';

const vtexio = (window as any)?.__RUNTIME__;

export class VtexIORuntime implements RuntimeStrategy {
  static type = RuntimeType.VtexIO;

  constructor(private config: RuntimeStrategyConfig) {
    const metadata: VtexIOMetadata = {
      vtexio,
    };
    this.config.runtime.updateMeta(metadata);
  }

  static isApplicable(): boolean {
    return !!vtexio;
  }

  async execute(): Promise<void> {
    const { runtime } = this.config;

    if (!vtexio.account) {
      runtime.logger.error(`missing the account in window.__RUNTIME__`);
      return;
    }

    await runtime.configureStore({ account: vtexio.account });
  }
}
