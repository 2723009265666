import { NizzaProduct } from './nizza';

export type HookName = 'transform' | 'validate' | 'end';

/**
 * ============ Action types ============
 */

export enum AddToCartActionTypes {
  OPEN_TAB = 'openTab',
  OPEN_VARIATION_SELECTOR = 'openVariationSelector',
  ADD_TO_CART = 'addToCart',
}

export type AddToCartCreateAction<T> = (data: T) => AddToCartAction;

export type AddToCartCreateActionExec<T> = (data: T) => any;

export type AddToCartAction = {
  type: AddToCartActionTypes;
  exec: () => any;
};

/**
 * ============ Factory types ============
 */

/**
 * The keys must be coincide with "AddToCartHookActionTypes" enum values
 */
export type AddToCartActionFactory = {
  openTab: AddToCartCreateAction<string>;
  openVariationSelector: AddToCartCreateAction<boolean>;
  addToCart: AddToCartCreateAction<{
    product: NizzaProduct;
    quantity?: number;
  }>;
};

export type AddToCartActionFactoryMap = {
  openTab: AddToCartCreateActionExec<string>;
  openVariationSelector: AddToCartCreateActionExec<boolean>;
  addToCart: AddToCartCreateActionExec<{
    product: NizzaProduct;
    quantity?: number;
  }>;
};

type AddToCartCreateNotify = (message: string) => void;

export type AddToCartNotifyFactory = {
  error: AddToCartCreateNotify;
  success: AddToCartCreateNotify;
};

/**
 * ============ Context types ============
 */

type TransformHookContext<T = Record<string, any>> = {
  product: NizzaProduct;
  data: T;
};

type ValidateHookContext<T = Record<string, any>> = {
  product: NizzaProduct;
  defaultAction: AddToCartAction;
  action: AddToCartActionFactory;
  data: T;
};

type EndHookContext<T = Record<string, any>> = {
  error: Error | string | null | unknown;
  notify: AddToCartNotifyFactory;
  actionExecuted: AddToCartAction;
  data: T;
};

/**
 * ============ Execution types ============
 */

/**
 * Context for the add to cart operation.
 */
export type HookContext<T = Record<string, any>> = {
  /** Indicates if the variation selector is open. */
  isVariationSelectorOpen: boolean;
  /** The quantity of the product to add to cart. */
  quantity?: number;
  data?: T;
};

/**
 * Represents the combined state of the add to cart operation.
 */
export type HookState<ContextData = Record<string, any>> =
  HooksInitConfig<ContextData> & {
    action: AddToCartAction;
  };

export type AddToCartHookStrategy<ContextData = Record<string, any>> = {
  validate: (state: HookState<ContextData>) => AddToCartAction;
};

/**
 * Initial configuration for the add to cart operation.
 */
export type HooksInitConfig<ContextData = Record<string, any>> = {
  /** The product to be added to the cart. */
  product: NizzaProduct;
  hookStrategy: AddToCartHookStrategy<ContextData>;
  /** A map of action factories for different add to cart actions. */
  actionFactory: AddToCartActionFactoryMap;
  /** Factory for creating notification actions. */
  notifyFactory: AddToCartNotifyFactory;
  /** Context for the add to cart operation. */
  context: HookContext<ContextData>;
  /** User-defined configuration for add to cart behavior. */
  userAddToCartConfig?: AddToCartConfig;
};

/**
 * ============ Event types ============
 */

export type ActionExecutedPayload = {
  actionType: AddToCartActionTypes;
  product: NizzaProduct;
  success: boolean;
  error: Error | string | null | unknown;
};

/**
 * ============ Main types ============
 */

interface AddToCartHooks<ContextData = Record<string, any>> {
  transform: (
    context: TransformHookContext<ContextData>,
  ) => NizzaProduct | Promise<NizzaProduct>;
  validate: (
    context: ValidateHookContext<ContextData>,
  ) => AddToCartAction | Promise<AddToCartAction>;
  end: (
    context: EndHookContext<ContextData>,
  ) => void | boolean | Promise<void | boolean>;
}

export interface AddToCartConfig {
  hooks?: Partial<AddToCartHooks>;
  actionFactory?: Partial<AddToCartActionFactoryMap>;
}
