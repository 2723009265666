import { NizzaAddToCartHandler, NizzaProduct } from '@nizza/core';
import { filterAvailableVtexProducts } from './filterAvailableProducts';

export const vtexLegacyAddToCart: NizzaAddToCartHandler = ({
  product,
  quantity = 1,
}) => {
  const item = buildVtexCartItem(product, quantity);
  const vtexjs = (window as any)?.vtexjs;

  if (!vtexjs) throw new Error('vtexLegacyAddToCart: vtexjs is not defined');
  return vtexjs.checkout.addToCart([item]);
};

export function buildVtexCartItem(product: NizzaProduct, quantity?: number) {
  let sellerId = product.sellerId;

  /**
   * This is a hopeless fix, usually the sellerId should come ready to use,
   * but for example the player in some streams mutates the product object,
   * causing the sellerId to be lost, until a root solution is found,
   * this fix It's fundamental
   */
  if (!sellerId) {
    try {
      const result = filterAvailableVtexProducts(product as any);
      const { seller } = result;
      sellerId = seller?.sellerId;
    } catch {
      /**
       * FIXME: For the recordings, the products are consulted from our DB but
       * currently the sellerId is not being saved, therefore while this
       * scenario is corrected, '1' will be used as the sellerId.
       */
      sellerId = '1';
    }
  }

  return {
    id: product.skuId,
    quantity: quantity || 1,
    seller: sellerId,
  };
}
