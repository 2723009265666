export function downloadFile(filename: string, url: string, ext: string) {
  fetch(url)
    .then(response => response.blob())
    .then(blob => jsDownload(blob, `${filename}.${ext}`));
}

export const jsDownload = (
  data: Blob,
  filename: string,
  mime?: string,
): boolean | ReturnType<typeof setTimeout> => {
  const blobData = [data];
  const blob = new Blob(blobData, {
    type: mime ?? 'application/octet-stream',
  });

  if (typeof (window as unknown as any).navigator.msSaveBlob !== 'undefined') {
    return (window as unknown as any).navigator.msSaveBlob(blob, filename);
  }

  const blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);

  const tempLink = document.createElement('a');

  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  return setTimeout(() => {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }, 200);
};
