import { action, computed, makeObservable, observable, when } from 'mobx';
import { NizzaAccount, PrivateNizzaAccount, getAccount } from '../accounts';
import { BroadcastChannelEventBus } from '../event-buses';
import {
  AccountStatus,
  AddToCartConfig,
  INizzaStore,
  NizzaConfig,
  NizzaProductDataSource,
} from '../types';
import { shortId } from '../utils/helpers';
import { Logger } from '../utils/logger';

export class NizzaStore implements INizzaStore {
  static readonly version = '7';
  private static _instance: NizzaStore;

  readonly instanceId = shortId();
  readonly log = new Logger('nz');
  readonly coreLogger = this.log.createLogger('core');

  private _config: NizzaConfig | null = null;
  private _account?: NizzaAccount | PrivateNizzaAccount;
  private _accountStatus: AccountStatus = 'idle';
  private _productDataSource: NizzaProductDataSource | undefined;
  private _eventBus = new BroadcastChannelEventBus('nizza', this.coreLogger);

  // Track the current account fetch request ID
  private activeAccountFetchId: string | null = null;

  private constructor() {
    this.coreLogger = Logger.withPrefix(
      this.coreLogger,
      `NizzaStore:${this.instanceId}`,
    );

    makeObservable<
      NizzaStore,
      | '_config'
      | '_account'
      | '_productDataSource'
      | '_accountStatus'
      | 'setAccount'
    >(
      this,
      {
        _config: observable,
        _account: observable,
        _productDataSource: observable,
        _accountStatus: observable,
        config: computed,
        account: computed,
        accountName: computed,
        accountStatus: computed,
        setAccount: action,
        configure: action,
        refetchAccount: action,
        setProductDataSource: action,
        setAddToCartConfig: action,
        setProductId: action,
      },
      { name: 'NizzaStore', autoBind: true },
    );

    this.refetchAccount = this.refetchAccount.bind(this);
  }

  static instance(): NizzaStore {
    const windowInstanceExists = !!window?.nizza?.store;

    if (!windowInstanceExists) {
      const newInstance = new NizzaStore();
      NizzaStore._instance = newInstance;
      NizzaStore._instance.coreLogger.debug('Created new NizzaStore instance');
    } else {
      NizzaStore._instance = window.nizza.store;
    }

    if (!window.nizza) {
      (window as any).nizza = {};
      (window as any).nz = {};
      NizzaStore._instance.coreLogger.debug('Initialized nizza window object');
    }

    window.nizza.store = NizzaStore._instance;
    window.nz = window.nizza;

    return NizzaStore._instance;
  }

  async configure(config: NizzaConfig) {
    try {
      this.coreLogger.debug('Running configure with config:', config);
      await this.updateConfig(config);
    } catch (err) {
      this.setAccountStatus('error');
      this.coreLogger.error('Error running configure', err);
    }
  }

  private async updateConfig(config: Partial<NizzaConfig> = {}) {
    const { account: currentAccount, authToken: currentAuthToken } =
      this._config ?? {};

    this._config = { ...this._config, ...config } as NizzaConfig;

    // Only fetch account if necessary (account/authToken has changed)
    if (
      this._config?.account &&
      (currentAccount !== this._config.account ||
        currentAuthToken !== this._config.authToken)
    ) {
      this.coreLogger.debug(
        `Fetching account data for account: ${
          this._config.account
        }, authToken provided: ${!!this._config.authToken}`,
      );
      await this.fetchAccount(this._config.account, this._config.authToken);
    } else {
      this.coreLogger.debug(
        'Skipped fetching account data as the account or authToken did not change',
      );
    }

    // Set product data source if available
    if (config.getProductDataSource) {
      this.setProductDataSource(await config.getProductDataSource());
    }
  }

  private async fetchAccount(accountName: string, authToken?: string) {
    this.setAccountStatus('loading');

    // Generate a unique ID for this fetch request
    const fetchId = shortId();
    this.activeAccountFetchId = fetchId;

    try {
      const account = await getAccount(accountName, authToken);

      // Check if this is the most recent fetch request
      if (this.activeAccountFetchId !== fetchId) {
        this.coreLogger.debug(
          'Fetch account request was superseded by a newer request',
        );
        return;
      }

      this.setAccount(account);
      this.setAccountStatus('success');
      this.coreLogger.debug('Account fetched and set successfully:', account);
    } catch (error) {
      // If the request failed, ensure we only log the error if it's still the current request
      if (this.activeAccountFetchId === fetchId) {
        this.setAccountStatus('error');
        this.coreLogger.error('Error fetching account:', error);
      }
    }
  }

  private setAccount(account: NizzaAccount) {
    this._account = account;
  }

  private setAccountStatus(status: AccountStatus) {
    this._accountStatus = status;
  }

  setProductDataSource(dataSource: NizzaProductDataSource) {
    this._productDataSource = dataSource;
  }

  setProductId(id: string) {
    if (!this._config) return;
    this._config.productId = id;
  }

  setOrderFormId(id: string) {
    if (!this._config) return;
    this._config.orderFormId = id;
  }

  setAddToCartConfig(config: AddToCartConfig) {
    if (!this._config) {
      const errorMessage =
        'You cannot set addToCartConfig before running configure()';
      this.coreLogger.error(errorMessage);
      throw new Error(errorMessage);
    }
    this._config.addToCartConfig = config;
  }

  setAuthToken(token: string) {
    this.updateConfig({ authToken: token });
  }

  async refetchAccount() {
    if (!this._config?.account) return;
    await this.fetchAccount(this._config.account, this._config.authToken);
  }

  get version() {
    return NizzaStore.version;
  }

  get config() {
    return this._config;
  }

  get account() {
    return this._account;
  }

  get accountStatus() {
    return this._accountStatus;
  }

  get accountName() {
    return this._config?.account;
  }

  isPvtAccount(
    account: NizzaAccount | PrivateNizzaAccount | undefined,
  ): account is PrivateNizzaAccount {
    return Boolean(account && this._config?.authToken);
  }

  isPubAccount(
    account: NizzaAccount | PrivateNizzaAccount | undefined,
  ): account is NizzaAccount {
    return Boolean(account && !this._config?.authToken);
  }

  get productDataSource() {
    return this._productDataSource;
  }

  get eventBus() {
    return this._eventBus;
  }
}

export const getNizza = (onInit?: (nizza: NizzaStore) => void) => {
  const nizza = NizzaStore.instance();

  when(
    () => !!nizza.account,
    () => onInit?.(nizza),
  );

  return nizza;
};
