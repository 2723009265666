/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';

export type UseScriptStatus = 'idle' | 'loading' | 'ready' | 'error';

export type UseScriptAttributes = {
  [key in keyof HTMLScriptElement | string]: string;
};

/** Configuration options for the script loading behavior. */
export type UseScriptConfig = {
  /** Whether the script should be loaded as an ES6 module. */
  module?: boolean;
  /** Flag to bypass the cache by appending a timestamp to the script URL. */
  cache?: boolean;
  /** Additional HTML attributes to set on the script element. */
  attrs?: UseScriptAttributes;
  /** Whether to enable or disable script injection. */
  enabled?: boolean;
};

/** Represents the state of a script element. */
export type UseScriptItem = {
  /** The source URL of the script. */
  src: string;
  /** The current status of the script ('idle', 'loading', 'ready', 'error'). */
  status: UseScriptStatus;
};

type ScriptContext = {
  scripts: UseScriptItem[];
  setScripts: React.Dispatch<React.SetStateAction<UseScriptItem[]>>;
  module: boolean;
  cache: boolean;
  attrs: UseScriptAttributes;
};

/**
 * Custom hook to load external scripts and monitor their statuses.
 *
 * @param {string | string[]} sources - The source URL(s) of the script(s) to load.
 * @param {UseScriptConfig} config - Optional configuration options.
 * @returns {UseScriptStatus | UseScriptStatus[]} The status of the loaded script(s).
 */
export function useScript<T extends string[] | string>(
  sources: T,
  config: UseScriptConfig = {},
): T extends string ? UseScriptStatus : UseScriptStatus[] {
  const { module = false, cache = true, attrs = {}, enabled = true } = config;

  const normalizedSources = useMemo(
    () => normalizeSources(sources, cache),
    [sources, cache],
  );

  const [scripts, setScripts] = useState<UseScriptItem[]>(normalizedSources);

  const context = useMemo(
    () => ({ scripts, setScripts, module, cache, attrs }),
    [scripts, setScripts, module, cache, attrs],
  );

  const loadScript = useCallback(
    (src: string) => {
      const existingScript = findScript(context, src);

      if (existingScript) {
        handleExistingScript(context, existingScript);
      } else {
        createAndLoadScript(context, src);
      }
    },
    [context],
  );

  useEffect(() => {
    if (!enabled) return;
    normalizedSources.forEach(script => loadScript(script.src));
  }, [normalizedSources, loadScript, enabled]);

  return useMemo(
    () => getScriptStatuses(sources, scripts),
    [scripts, sources],
  ) as T extends string ? UseScriptStatus : UseScriptStatus[];
}

/**
 * Get the statuses of the scripts.
 *
 * @param {string | string[]} sources - The source URL(s) of the script(s).
 * @param {UseScriptItem[]} scripts - The array of script items with their statuses.
 * @returns {UseScriptStatus | UseScriptStatus[]} The statuses of the scripts.
 */
function getScriptStatuses(
  sources: string | string[],
  scripts: UseScriptItem[],
): UseScriptStatus | UseScriptStatus[] {
  return typeof sources === 'string'
    ? scripts[0]?.status || 'idle'
    : scripts.map(script => script.status);
}

/**
 * Normalize the sources into an array of script items with initial status 'idle'.
 *
 * @param {string | string[]} sources - The source URL(s) of the script(s).
 * @param {boolean} cache - Whether to use cache-busting.
 * @returns {UseScriptItem[]} Array of normalized script items.
 */
function normalizeSources(
  sources: string | string[],
  cache: boolean,
): UseScriptItem[] {
  const srcArray = Array.isArray(sources) ? sources : [sources];
  return srcArray.map(src => ({
    src: normalizeSrc(src, cache),
    status: 'idle',
  }));
}

/**
 * Create and load a new script element.
 *
 * @param {ScriptContext} context - The script context containing configuration and state.
 * @param {string} src - The source URL of the script.
 */
function createAndLoadScript(context: ScriptContext, src: string) {
  const { module, attrs } = context;
  const script = document.createElement('script');

  script.src = src;
  script.async = true;
  if (module) script.type = 'module';

  Object.entries(attrs).forEach(([key, value]) =>
    script.setAttribute(key, value),
  );

  document.body.appendChild(script);

  script.addEventListener('load', () =>
    updateScriptStatus(context, script, 'ready'),
  );
  script.addEventListener('error', () =>
    updateScriptStatus(context, script, 'error'),
  );

  updateScriptStatus(context, script, 'loading');
}

/**
 * Handle an existing script element.
 *
 * @param {ScriptContext} context - The script context containing configuration and state.
 * @param {HTMLScriptElement} script - The existing script element.
 */
function handleExistingScript(
  context: ScriptContext,
  script: HTMLScriptElement,
) {
  const status =
    (script.getAttribute('data-status') as UseScriptStatus) || 'idle';

  updateScriptStatus(context, script, status);

  if (['loading', 'idle'].includes(status)) {
    script.addEventListener('load', () =>
      updateScriptStatus(context, script, 'ready'),
    );
    script.addEventListener('error', () =>
      updateScriptStatus(context, script, 'error'),
    );
  }
}

/**
 * Update the status of a script element.
 *
 * @param {ScriptContext} context - The script context containing configuration and state.
 * @param {HTMLScriptElement} script - The script element to update.
 * @param {UseScriptStatus} status - The new status of the script.
 */
function updateScriptStatus(
  context: ScriptContext,
  script: HTMLScriptElement,
  status: UseScriptStatus,
) {
  const { scripts, setScripts, cache } = context;
  const src = cache ? script.src : script.src.split('?')[0];
  const localScript = scripts.find(x => x.src.includes(src));

  // Prevent unnecessary updates if the status hasn't changed
  if (localScript?.status === status) return;

  // Update the script's data-status attribute and the state
  script.setAttribute('data-status', status);
  setScripts(p => p.map(x => (x.src.includes(src) ? { ...x, status } : x)));
}

/**
 * Find an existing script element in the DOM.
 *
 * @param {ScriptContext} context - The script context containing configuration and state.
 * @param {string} src - The source URL of the script.
 * @returns {HTMLScriptElement | null} The existing script element, or null if not found.
 */
function findScript(
  context: ScriptContext,
  src: string,
): HTMLScriptElement | null {
  const _src = context.cache ? src : src.split('?')[0];
  return document.querySelector<HTMLScriptElement>(`script[src*="${_src}"]`);
}

/**
 * Normalize the source URL of a script.
 *
 * @param {string} src - The original source URL.
 * @param {boolean} cache - Whether to use cache-busting.
 * @returns {string} The normalized source URL.
 */
function normalizeSrc(src: string, cache: boolean): string {
  return cache ? src : `${src}?_c=${Date.now()}`;
}
