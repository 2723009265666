export interface IAggregateRoot {
  toPrimitives: () => Record<string, any>;
}

export class AggregateRoot<Aggregate, Props> {
  private aggregate: Aggregate;
  private _numSetPropsApplied = 0;

  constructor(aggregate: Aggregate) {
    this.aggregate = aggregate;
  }

  setProps(props: Partial<Props>) {
    for (const [key, value] of Object.entries(props)) {
      if (key in (this as any).aggregate) {
        (this.aggregate as any)[key] = value;
      }
    }

    this._numSetPropsApplied++;
  }

  get isFirstSetProps() {
    return this._numSetPropsApplied === 1;
  }
}

export function createAggregateRoot<Props, Aggregate>(aggregate: Aggregate) {
  return new AggregateRoot<Aggregate, Props>(aggregate);
}
