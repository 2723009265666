import {
  AccountVariationsConfig,
  NizzaProduct,
  NizzaVariationSelector,
  VtexProduct,
} from '@nizza/core';
import * as R from 'ramda';
import { buildVariationCodeWithNames } from '../variation-code-builder';
import { filterAvailableVtexProducts } from './filterAvailableProducts';
import { fixLinkHost } from './fixLinkHost';
import { getPageUrl, isMyVTEX } from './utils';
import { omittedVariationsConfig } from './omittedVariationsConfig';

export function vtexToNizzaProduct(
  vtexProduct: VtexProduct,
  accountHost: string | null = null,
  applyTax = false,
  variationsConfig?: AccountVariationsConfig,
): NizzaProduct {
  let p: VtexProduct = { ...vtexProduct };

  const result = filterAvailableVtexProducts(p);
  const { item, seller, isAvailable } = result;

  const getPrice = (price: number) =>
    applyTax ? price + (seller?.commertialOffer?.Tax || 0) : price;

  const getHost = R.ifElse(
    R.pipe(getPageUrl, isMyVTEX),
    R.pipe(getPageUrl, R.constructN(1, URL), R.prop('host')),
    R.always(accountHost!),
  );

  const priceWithDiscount = getPrice(seller.commertialOffer.Price);
  const price = getPrice(seller.commertialOffer.ListPrice);

  if (variationsConfig?.omitted?.length) {
    p = omittedVariationsConfig(p, variationsConfig);
  }

  const items = p.items.map(item => ({
    ...item,
    variationCode: buildVariationCodeWithNames(item),
  }));

  let product: NizzaProduct = {
    /**
     * Some integrations like the add to cart in vtex.io add
     * additional information in the product object itself,
     * that's why this is necessary "...product"
     **/
    ...p,

    items,
    price,
    priceWithDiscount,
    isAvailable,

    id: p.productId,
    skuId: item?.itemId,
    sellerId: seller?.sellerId,
    name: p?.productName,
    imageUrl: item?.images[0]?.imageUrl,
    pdpLink: p.link,
    addToCartLink: seller?.addToCartLink,
    variationSelector: (p?.skuSpecifications as NizzaVariationSelector[]) || [],
  };

  if (accountHost) {
    const host = getHost();
    product = fixLinkHost(product, host);
  }

  return product;
}
