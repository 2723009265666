import { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { AppModuleStoreList, StoreClass } from '../types';

interface Props {
  stores: AppModuleStoreList;
  children?: ReactNode;
}

let globalStores = new Map<any, any>();
const storeContext = createContext<typeof globalStores>(globalStores);

export const StoreProvider: FC<Props> = ({ children, stores }) => {
  const parentContext = useContext(storeContext);

  const context = useMemo(() => {
    const container = new Map();
    stores.forEach(store => container.set(store.class, store.value));
    return (globalStores = new Map([...parentContext, ...container]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <storeContext.Provider value={context}>{children}</storeContext.Provider>
  );
};

export function useStore<T>(identifier: StoreClass<T>): T {
  const context = useContext(storeContext);
  return context.get(identifier);
}

export function getStore<T>(identifier: StoreClass<T>): T {
  return globalStores.get(identifier);
}
