/**
 * Data Transfer Object for DomainError.
 */
interface DomainErrorDTO {
  /** Name of the error */
  name: string;

  /** Error message providing more context */
  message: string;

  /** Optional value related to the error context */
  value?: any;

  /** Human-readable stack trace of the error */
  stack?: string;
}

/**
 * Represents a domain-specific error that extends the base JavaScript Error class.
 */
export class DomainError extends Error {
  /**
   * Creates a new instance of DomainError.
   * @param name - The name of the error.
   * @param message - A detailed message describing the error.
   * @param value - Optional value that provides additional context about the error.
   */
  constructor(name: string, message: string, public value?: any) {
    super(message);
    this.name = name;

    // Capture the stack trace at the point where this error is instantiated.
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  /**
   * Converts the DomainError to a plain object for transfer or logging.
   * @returns An object representing the error in a more readable format.
   */
  toDTO(): DomainErrorDTO {
    return {
      name: this.name,
      message: this.message,
      ...(this.value && { value: this.value }),
      // Include a human-readable stack trace if available.
      ...(this.stack && { stack: this.formatStack() }),
    };
  }

  /**
   * Formats the error stack trace into a human-readable string.
   * @returns A formatted stack trace string.
   */
  private formatStack(): string {
    return this.stack
      ? this.stack
          .split('\n') // Split stack trace into lines
          .map(line => line.trim()) // Remove excess whitespace
          .join(' | ') // Join the lines with a pipe delimiter for clarity
      : '';
  }
}
