// import { Environment } from '../types';
import { Environment } from '@nizza/core';
import coreEnvs from '@nizza/core/envs/environment';

export const config = {
  ...coreEnvs,
  account: '__ACCOUNT__',
};

export const environment: Environment<typeof config> = config;
export default environment;
