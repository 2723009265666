import { ChatMessage } from '../../../types';

export const addQuestionData = (msg: ChatMessage): ChatMessage => {
  msg.data = msg.data.replace('replace_gif', msg.urlGif ?? '');
  const hasQuestionData = msg.data.indexOf('::question__') > -1;

  if (hasQuestionData) {
    const [responseMsg, questionMsg] = msg.data.split('::') || ['none', 'none'];

    const questionString = questionMsg.split('question__')[1];
    const questionData = {
      response: responseMsg.replace('response__', ''),
      question: JSON.parse(questionString),
    };

    return {
      ...msg,
      data: questionData.response,
      isResponse: hasQuestionData,
      responseData: msg.data,
      question: hasQuestionData ? questionData.question : null,
    };
  }
  return msg;
};
