import { EventNames } from '../constants';
import { getNizza } from '../nizza-store';
import {
  ActionExecutedPayload,
  AddToCartAction,
  AddToCartActionFactory,
  AddToCartActionFactoryMap,
  AddToCartActionTypes,
  AddToCartCreateActionExec,
  HookName,
  HooksInitConfig,
  HookState,
} from '../types';
import { Logger } from '../utils';

const { coreLogger } = getNizza();
const logger = Logger.withPrefix(coreLogger, 'addToCartHooks');

const defaultErrorMessage = 'addToCartHooks.notification.error';

export const addToCartMessages = {
  errorAddingToCart: defaultErrorMessage,
  errorOpeningVariationSelector: defaultErrorMessage,
  errorOpeningNewTab: defaultErrorMessage,
  successAddingToCart: 'addToCartHooks.notification.success',
};

/**
 * Executes the add to cart hooks.
 * @async
 * @param {HooksInitConfig} config - The initial configuration for the add to cart operation.
 */
export async function runAddToCartHooks<
  ContextData extends Record<string, any>,
>(config: HooksInitConfig<ContextData>) {
  const initialState: HookState<ContextData> = {
    ...config,
    action: {
      type: AddToCartActionTypes.ADD_TO_CART,
      exec: () => logger.error('action no implemented in validate hook'),
    },
  };

  try {
    const transformState = await transform(initialState as any);
    const validateState = await validate(transformState);
    await end(validateState);
  } catch (err) {
    logger.error('error unhandled', err);
  }
}

/**
 * Transforms the state before adding to cart.
 * @async
 * @param {HookState} state - The current state of the add to cart operation.
 * @returns {Promise<HookState>} The transformed state.
 */
async function transform(state: HookState): Promise<HookState> {
  const { context, userAddToCartConfig } = state;
  const transformHook = userAddToCartConfig?.hooks?.transform;
  let product = state.product;

  if (transformHook) {
    try {
      product = await transformHook({
        product: { ...product },
        data: context.data ?? {},
      });
    } catch (err) {
      handleError('transform', err);
    }
  }

  return {
    ...state,
    product,
  };
}

/**
 * Validates the state before proceeding with the add to cart action.
 * @async
 * @param {HookState} state - The current state of the add to cart operation.
 * @returns {Promise<HookState>} The validated state.
 */
async function validate(state: HookState): Promise<HookState> {
  const { context, hookStrategy, userAddToCartConfig, product } = state;
  const actionFactory = createActionFactory(state);
  const validateHook = userAddToCartConfig?.hooks?.validate;

  let action: AddToCartAction = hookStrategy.validate({
    ...state,
    actionFactory,
  });

  if (validateHook) {
    try {
      action = await validateHook({
        product: { ...product },
        defaultAction: { ...action },
        action: actionFactory,
        data: context.data ?? {},
      });
    } catch (err) {
      handleError('validate', err);
    }
  }

  return {
    ...state,
    action,
    actionFactory,
  };
}

/**
 * Finalizes the add to cart operation.
 * @async
 * @param {HookState} state - The current state of the add to cart operation.
 */
async function end(state: HookState) {
  const { eventBus } = getNizza();
  const { product, userAddToCartConfig, notifyFactory, action, actionFactory } =
    state;
  const { isVariationSelectorOpen, data = {} } = state.context;
  const endHook = userAddToCartConfig?.hooks?.end;
  const actionIsAddToCart = action.type === AddToCartActionTypes.ADD_TO_CART;

  let actionError: unknown = null;
  let userControlsNotifications = false;

  const closeVariationSelector = () => {
    if (!actionIsAddToCart || !isVariationSelectorOpen) return;
    actionFactory.openVariationSelector(false).exec();
  };

  const addToCartNotification = () => {
    if (!actionIsAddToCart || userControlsNotifications) return;

    if (actionError) {
      notifyFactory.error(addToCartMessages.errorAddingToCart);
    } else {
      notifyFactory.success(addToCartMessages.successAddingToCart);
    }
  };

  const publishEvent = () => {
    const payload: ActionExecutedPayload = {
      product,
      actionType: action.type,
      success: !actionError,
      error: actionError,
    };

    eventBus.emit(EventNames.ATCH_ACTION_EXECUTED, payload);
  };

  try {
    if (!action.type || !action.exec) {
      throw new Error("Invalid action object, missing keys 'type' or 'exec'");
    }

    await action.exec();
  } catch (err: any) {
    handleError('end', err);
    actionError = err;
  }

  if (endHook) {
    try {
      const hookResult = await endHook({
        data,
        error: actionError,
        notify: notifyFactory,
        actionExecuted: action,
      });

      userControlsNotifications = !!hookResult;
    } catch (err) {
      handleError('end', err);
    }
  }

  closeVariationSelector();
  addToCartNotification();
  publishEvent();
}

/**
 * Creates an action factory based on the current state.
 * @param {HookState} state - The current state of the add to cart operation.
 * @returns {AddToCartActionFactory} The created action factory.
 */
export function createActionFactory(state: HookState): AddToCartActionFactory {
  const { actionFactory, userAddToCartConfig } = state;

  const matchExecWithType = (
    obj: Partial<AddToCartActionFactoryMap> | undefined,
  ) => {
    return Object.entries(obj ?? {}).reduce(
      (obj, [k, v]) => ({
        ...obj,
        [k]: (data: any) => ({
          type: k,
          exec: () => (v as AddToCartCreateActionExec<any>)(data),
        }),
      }),
      {} as AddToCartActionFactory,
    );
  };

  return {
    ...matchExecWithType(actionFactory),
    ...matchExecWithType(userAddToCartConfig?.actionFactory),
  };
}

/**
 * Handles errors occurred during add to cart hooks execution.
 * @param {HookName} hookName - The name of the hook where the error occurred.
 * @param {unknown} err - The error that occurred.
 */
function handleError(hookName: HookName, err: unknown) {
  logger.error(`addToCartHooks error running ${hookName}: `, err);
}
