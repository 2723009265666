import { RuntimeType, VtexFastStoreMetadata } from '../../types';
import { RuntimeStrategy, RuntimeStrategyConfig } from '../types';

const vtexfaststore = (window as any)?.faststore_sdk_stores;

export class VtexFastStoreRuntime implements RuntimeStrategy {
  static type = RuntimeType.VtexFastStore;

  constructor(private config: RuntimeStrategyConfig) {
    const metadata: VtexFastStoreMetadata = {
      vtexfaststore,
    };
    this.config.runtime.updateMeta(metadata);
  }

  static isApplicable(): boolean {
    return !!vtexfaststore;
  }

  async execute(): Promise<void> {
    const { runtime } = this.config;

    if (!vtexfaststore.account) {
      runtime.logger.error(
        `missing the account in window.faststore_sdk_stores`,
      );
      return;
    }

    await runtime.configureStore({ account: vtexfaststore.account });
  }
}
