const {
  NZ_AWS_REGION,
  NZ_BACKOFFICE_BACK_URL,
  NZ_CORE_SERVICES_GRAPHQL_URL,
  NZ_CORE_SERVICES_REST_URL,
} = import.meta.env;

export const environment = {
  AWS: {
    region: NZ_AWS_REGION,
    cognito: {
      userPoolId: 'us-east-1_knSlGEmJ0',
      userPoolWebClientId: '3hmbfhf7nl3kq20qr9i5lihf1p',
    },
  },

  //  Back services
  apiUrls: {
    restV1:
      NZ_BACKOFFICE_BACK_URL ||
      'https://pabprg32bj.execute-api.us-east-1.amazonaws.com',
    restV2:
      NZ_CORE_SERVICES_REST_URL || 'https://core-services-dev.nizza.com/api',
    graphql:
      NZ_CORE_SERVICES_GRAPHQL_URL ||
      'https://core-services-dev.nizza.com/graphql',
    corsProxy: 'https://1qmd8jteh1.execute-api.us-east-1.amazonaws.com',
  },

  publicUrls: {
    //  Public app url
    platformSite: 'https://platformdev.nizza.com',

    // Nizza CDN
    nizzaCdn: 'https://cdn.nizza.com',

    //  Bundle
    bundleSite: 'https://bundledev.nizza.com',

    // WSS IVS CHAT
    wssIVSChat: 'wss://edge.ivschat.us-east-1.amazonaws.com',

    // Global Page
    globalPage: 'https://live-dev.nizza.com',
  },
};

export default environment;
