import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { AddToCart, ChatMessage, HighlightProduct } from '../../../types';
import { ChatClientSocket } from '../core';
import { chatMessageListAtom } from '../state';
import { normalizeChatMessage } from '../utils';
import { useChatSocketHandlers, useConnectChatSocket } from './';

declare interface Props {
  eventId: string;
  userName: string;
}

export const useChatSocketClient = (props: Props): ChatClientSocket => {
  const { eventId, userName } = props;
  const [showLoader, setShowLoader] = useState(false);
  const [pinnedMessage, setPinnedMessage] = useState<ChatMessage | undefined>();
  const [highlightProduct, setHighlightProduct] = useState<
    HighlightProduct | undefined
  >();
  const [productsInCart, setProductsInCart] = useState<AddToCart[]>([]);

  const { handleEvent } = useChatSocketHandlers();
  const setChatMessageList = useSetAtom(chatMessageListAtom);

  const handleMessageCustom = (msg: any) => {
    setChatMessageList(prev => {
      return [normalizeChatMessage(prev, msg), ...prev];
    });
    setShowLoader(false);
  };

  const handleEventCustom = (event: any) => {
    handleEvent(event);
    if (event.EventName === 'sendpinmessage') {
      const msg = JSON.parse(event.Attributes?.data);
      setPinnedMessage({
        data: msg.data,
        id: msg.id,
        username: msg.username,
      } as unknown as ChatMessage);
    }
    if (event.EventName === 'sendunpinmessage') {
      setPinnedMessage(undefined);
    }
    if (event.EventName === 'sendhighlightproduct') {
      const msg = JSON.parse(event.Attributes?.data);

      setHighlightProduct({
        products: msg.productIds,
        collection: msg.collection,
        livestreamingId: msg.idEvent,
      });
    }
    if (event.EventName === 'sendaddtocart') {
      const msg = JSON.parse(event.Attributes?.data);

      setProductsInCart(prev => [
        ...prev,
        {
          id: msg.id,
          sessionId: msg.sessionId,
          productId: msg.productId,
          productImageUrl: msg.productImageUrl,
          productName: msg.productName,
        },
      ]);
    }
  };

  const {
    socket,
    chatMessageList: chat,
    setChatMessageList: setChat,
  } = useConnectChatSocket({
    onMessage: handleMessageCustom,
    onEvent: handleEventCustom,
    eventId,
    userName,
  });

  return {
    socket,
    chat,
    showLoader,
    pinnedMessage,
    highlightProduct,
    productsInCart,
    setChat,
    setShowLoader,
    setPinnedMessage,
    setProductsInCart,
  };
};

export default useChatSocketClient;
