const {
  NZ_AWS_REGION,
  NZ_BACKOFFICE_BACK_URL,
  NZ_CORE_SERVICES_GRAPHQL_URL,
  NZ_CORE_SERVICES_REST_URL,
} = import.meta.env;

export const environment = {
  AWS: {
    region: NZ_AWS_REGION,
    cognito: {
      userPoolId: 'us-east-1_EZ7wuZ4ig',
      userPoolWebClientId: '2jok7cst8ebf6ppscrhji57gvr',
    },
  },

  //  Back services
  apiUrls: {
    restV1:
      NZ_BACKOFFICE_BACK_URL ||
      'https://b133h34s56.execute-api.us-east-1.amazonaws.com',
    restV2:
      NZ_CORE_SERVICES_REST_URL || 'https://core-services-prod.nizza.com/api',
    graphql:
      NZ_CORE_SERVICES_GRAPHQL_URL ||
      'https://core-services-prod.nizza.com/graphql',
    corsProxy: 'https://3hvqfl2xcg.execute-api.us-east-1.amazonaws.com',
  },

  publicUrls: {
    //  Public app url
    platformSite: 'https://platform.nizza.com',

    // Nizza CDN
    nizzaCdn: 'https://cdn.nizza.com',

    //  Bundle
    bundleSite: 'https://bundle.nizza.com',

    // WSS IVS CHAT
    wssIVSChat: 'wss://edge.ivschat.us-east-1.amazonaws.com',

    // Global Page
    globalPage: 'https://live.nizza.com',
  },
};

export default environment;
