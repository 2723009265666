import { useMemo } from 'react';
import { useSelect, UseSelectConfig } from '../mobx';
import { getNizza, NizzaStore } from './NizzaStore';

/**
 * Returns the NizzaStore using memoization.
 *
 * @return {NizzaStore} The NizzaStore object
 */
export function useNZ(): NizzaStore {
  return useMemo(() => getNizza(), []);
}

/**
 * Hook to select values from the NizzaStore.
 *
 * @param selector - Function to select a value from the store.
 * @param config - Optional configuration object with `defaultValue` and `deps`.
 * @returns The selected value from the NizzaStore.
 *
 * @example
 * // Select accountName with a fallback value
 * const accountName = useNizza(store => store.accountName || 'Guest');
 *
 * // Select accountName using a default value through config
 * const accountName = useNizza(store => store.accountName, { defaultValue: 'Guest' });
 *
 * // Select productList with dependencies
 * const productList = useNizza(store => store.products, { deps: [dep1] });
 *
 * // Return a custom object with multiple store properties
 * const accountInfo = useNizza(store => ({
 *   name: store.accountName || 'Guest',
 *   status: store.accountStatus,
 *   isPrivate: store.isPvtAccount(store.account),
 * }));
 */
export function useNizza<T>(
  selector: (store: NizzaStore) => T,
  config?: UseSelectConfig<T>,
): T {
  const store = useNZ();
  return useSelect(() => selector(store), config);
}
