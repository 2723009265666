/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useAccount } from '../../../accounts';
import { eventChatRoomService } from '../core';

type Props = {
  eventId: string;
  userName: string;
  enabled?: boolean;
};

const key = (eventId: string, userName: string) => [
  'eventChatRoom',
  eventId,
  userName,
];

export const useEventChatRoom = (props: Props) => {
  const { eventId, userName, enabled = true } = props;
  const { accountName } = useAccount();
  const [token, setToken] = useState('');

  const getQuery = useQuery({
    queryKey: key(eventId, userName),
    queryFn: () => eventChatRoomService.get({ eventId, userName }),
    enabled: !!accountName && !!eventId && enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    if (!accountName) return;
    eventChatRoomService.setAccount(accountName);
  }, [accountName]);

  useEffect(() => {
    const token = getQuery.data?.token;
    if (!token) return;
    setToken(token);
  }, [getQuery.data]);

  return {
    getQuery,
    token,
  };
};

export default useEventChatRoom;
