import { i18n } from 'i18next';
import { TranslationFiles, Translations } from '../types';

export function buildLanguageTranslations(
  langTranslations: Record<string, Record<string, string>>,
): Record<string, string> {
  const fileTranslations = Object.values(langTranslations);
  return Object.assign({}, ...fileTranslations);
}

export function buildTranslations(
  translationFiles: TranslationFiles,
): Translations {
  return Object.entries(translationFiles).reduce(
    (result, [lang, langTranslations]) => {
      const translations = buildLanguageTranslations(langTranslations);
      return { ...result, [lang]: translations };
    },
    {} as Translations,
  );
}

export function registerTranslationsOf(
  i18Instance: i18n,
  translationFiles: TranslationFiles,
) {
  const translations = buildTranslations(translationFiles);

  Object.entries(translations).forEach(([language, translations]) => {
    i18Instance.addResourceBundle(language, 'translation', translations, true);
  });
}
