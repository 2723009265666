import { AccountCurrencyConfig } from '@nizza/core';

/**
 * Retrieves the currency formatting options.
 *
 * @param {Partial<AccountCurrencyConfig>} [manualOptions] - Optional manual currency options.
 * @returns {AccountCurrencyConfig} - The final options combining manual and default options.
 */
const getOptions = (
  manualOptions?: Partial<AccountCurrencyConfig>,
): AccountCurrencyConfig => {
  const { currencyFormat, currencySymbol } = window as any;

  return {
    decimalDigits:
      manualOptions?.decimalDigits ??
      currencyFormat?.CurrencyDecimalDigits ??
      2,
    decimalSeparator:
      manualOptions?.decimalSeparator ??
      currencyFormat?.CurrencyDecimalSeparator ??
      '.',
    thousandsSeparator:
      manualOptions?.thousandsSeparator ??
      currencyFormat?.CurrencyGroupSeparator ??
      ',',
    symbol: manualOptions?.symbol ?? currencySymbol ?? '$',
    startsWithCurrencySymbol:
      manualOptions?.startsWithCurrencySymbol ??
      currencyFormat?.StartsWithCurrencySymbol ??
      true,
    currencyCode: manualOptions?.currencyCode ?? 'USD',
    cultureCode: manualOptions?.cultureCode ?? 'en-US',
  } satisfies AccountCurrencyConfig;
};

/**
 * Formats a number as a currency string.
 *
 * @param {number} amount - The numeric amount to format.
 * @param {AccountCurrencyConfig} options - The currency formatting options.
 * @returns {string} - The formatted currency string.
 */
const formatNumber = (
  amount: number,
  options: AccountCurrencyConfig,
): string => {
  return new Intl.NumberFormat(options.cultureCode, {
    style: 'currency',
    currency: options.currencyCode,
    minimumFractionDigits: options.decimalDigits,
    maximumFractionDigits: options.decimalDigits,
  }).format(amount);
};

/**
 * Deconstructs a formatted currency string into its components.
 *
 * @param {string} formattedNum - The formatted currency string.
 * @param {AccountCurrencyConfig} options - The currency formatting options.
 * @returns {string} - A JSON string representing the deconstructed currency.
 */
const deconstructCurrency = (
  formattedNum: string,
  options: AccountCurrencyConfig,
): string => {
  const [integerPart, decimalPart] = formattedNum.split(
    options.decimalSeparator,
  );
  return JSON.stringify({
    integerPart: integerPart.replace(
      new RegExp(`\\${options.thousandsSeparator}`, 'g'),
      '',
    ),
    decimalPart: decimalPart || '',
    symbol: options.symbol,
    decimalSeparator: options.decimalSeparator,
  });
};

/**
 * Formats a number as a currency string, with an option to deconstruct the result.
 *
 * @param {number} amount - The numeric amount to format.
 * @param {Partial<AccountCurrencyConfig>} [manualOptions] - Optional manual currency options.
 * @param {boolean} [deconstructedIsNeeded] - Whether the result should be deconstructed.
 * @returns {string} - The formatted currency string or deconstructed currency JSON.
 */
export const formatCurrency = (
  amount: number,
  manualOptions?: Partial<AccountCurrencyConfig>,
  deconstructedIsNeeded?: boolean,
): string => {
  const options = getOptions(manualOptions);
  const formattedNum = formatNumber(amount, options);

  return deconstructedIsNeeded
    ? deconstructCurrency(formattedNum, options)
    : formattedNum;
};
