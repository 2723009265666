import { RenderProps, RuntimeType, VtexLegacyMetadata } from '../../types';
import { getScriptParams } from '../../utils';
import { RuntimeStrategy, RuntimeStrategyConfig } from '../types';

const vtexjs = (window as any)?.vtexjs;

export class VtexLegacyRuntime implements RuntimeStrategy {
  static type = RuntimeType.VtexLegacy;

  constructor(private config: RuntimeStrategyConfig) {
    const metadata: VtexLegacyMetadata = {
      vtexjs,
    };
    this.config.runtime.updateMeta(metadata);
  }

  static isApplicable(): boolean {
    return !!vtexjs;
  }

  async execute(): Promise<void> {
    const { runtime } = this.config;
    let props: RenderProps = {};

    const scriptId = `${runtime.componentId.value}-script`;
    const urlParams = getScriptParams(scriptId);
    props = Object.fromEntries(urlParams.entries());

    if (!props.account) {
      runtime.logger.error(
        `missing the account in the search params of the script #${scriptId}`,
      );
      return;
    }

    await runtime.configureStore({ account: props.account });
  }
}
