import { PrivateNizzaAccount } from '../../domain';
import { RawPrivateAccount } from '../dtos';

export function rawPrivateAccountToDomain(
  raw: RawPrivateAccount,
): PrivateNizzaAccount {
  const {
    host,
    account,
    username,
    createdAt,
    subscriptionType,
    installationDateApp,
    ...rest
  } = raw;

  return {
    ...rest,
    account,
    host: host ?? null,
    username: username ?? account,
    createdAt: new Date(createdAt),
    subscriptionType: subscriptionType ?? null,
    installationDateApp: new Date(installationDateApp),
  };
}
