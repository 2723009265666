import { NizzaSetUTMHandler, VtexOrderForm } from '@nizza/core';

export const vtexLegacySetUTM: NizzaSetUTMHandler = utm => {
  try {
    const vtexjs = (window as any)?.vtexjs;

    if (vtexjs) throw new Error('setLegacyUTM: vtexjs is not defined');
    if (!utm) throw new Error('setLegacyUTM: utm is not defined');

    const { getOrderForm, sendAttachment } = vtexjs.checkout;

    return getOrderForm().then((orderForm: VtexOrderForm) => {
      const marketingData = {
        ...orderForm.marketingData,
        utmSource: utm,
      };
      return sendAttachment('marketingData', marketingData);
    });
  } catch (error) {
    console.error('setLegacyUTM: error setting UTM: ', error);
  }
};
