export type ScriptStatus = 'idle' | 'loading' | 'ready' | 'error';

export type ScriptEvents = Exclude<ScriptStatus, 'idle'>;

export type ScriptAttributes = {
  [key in keyof HTMLScriptElement | string]: string;
};

/** Configuration options for the script loading behavior. */
export type ScriptConfig = {
  /** Whether the script should be loaded as an ES6 module. */
  module?: boolean;
  /** Flag to bypass the cache by appending a timestamp to the script URL. */
  cache?: boolean;
  /** Additional HTML attributes to set on the script element. */
  attrs?: ScriptAttributes;
};

export type EventCallback = (data?: any) => void;

/**
 * Class responsible for dynamically injecting a script element into the DOM,
 * managing its loading state, and providing event hooks for script status changes.
 * This class handles the script's creation, its addition to the document, and
 * monitors the script's loading progress. Users can subscribe to 'loading', 'ready',
 * and 'error' events to execute custom callbacks based on the script's status.
 */
export class ScriptInjector {
  private src: string;
  private module: boolean;
  private attrs: ScriptAttributes;
  private events: Record<ScriptEvents, EventCallback[]>;
  private script!: HTMLScriptElement;
  private cache: boolean;

  /**
   * Creates an instance of ScriptInjector.
   * @param {string} src - The source URL of the script.
   * @param {ScriptConfig} [config={}] - Optional configuration options.
   */
  constructor(src: string, config: ScriptConfig = {}) {
    this.src = src;
    this.cache = config.cache || false;
    this.module = config.module || false;
    this.attrs = config.attrs || {};
    this.events = {
      loading: [],
      ready: [],
      error: [],
    };
  }

  /**
   * Subscribe to script events.
   * @param {ScriptEvents} event - The event to subscribe to ('loading', 'ready', 'error').
   * @param {EventCallback} callback - The callback function to execute when the event occurs.
   */
  public on(event: ScriptEvents, callback: EventCallback) {
    this.events[event]?.push(callback);
  }

  /**
   * Execute the script injection process.
   */
  public execute() {
    this.script = this.getScript()!;

    if (this.script) {
      this.handleScript();
    } else {
      this.loadScript();
    }
  }

  /**
   * Get an existing script element if it exists in the DOM.
   * @returns {HTMLScriptElement | null} - The existing script element or null if not found.
   */
  private getScript(): HTMLScriptElement | null {
    return document.querySelector<HTMLScriptElement>(
      `script[src*="${this.src}"]`,
    );
  }

  /**
   * Handle an existing script element.
   */
  private handleScript() {
    const status =
      (this.script.getAttribute('data-status') as ScriptStatus) || 'idle';

    this.updateStatus(status);

    if (status === 'loading' || status === 'idle') {
      this.addEventListeners();
    }
  }

  /**
   * Create and load a new script element.
   */
  private loadScript() {
    this.script = document.createElement('script');

    this.script.src = this.cache ? this.src : `${this.src}?_c=${Date.now()}`;
    this.script.async = true;
    if (this.module) this.script.type = 'module';

    Object.entries(this.attrs).forEach(([key, value]) =>
      this.script.setAttribute(key, value),
    );

    document.body.appendChild(this.script);
    this.addEventListeners();
    this.updateStatus('loading');
  }

  /**
   * Add event listeners to the script element.
   */
  private addEventListeners() {
    this.script.addEventListener('load', () => this.updateStatus('ready'));
    this.script.addEventListener('error', () => this.updateStatus('error'));
  }

  /**
   * Update the status of the script element.
   * @param {ScriptStatus} status - The new status of the script.
   */
  private updateStatus(status: ScriptStatus) {
    this.script.setAttribute('data-status', status);
    this.triggerEvent(status);
  }

  /**
   * Trigger the callbacks for a given event.
   * @param {ScriptStatus} status - The event status to trigger.
   */
  private triggerEvent(status: ScriptStatus) {
    const callbacks = this.events[status as ScriptEvents];
    if (!callbacks) return;
    callbacks.forEach(callback => callback());
  }
}
