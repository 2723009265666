/**
 * Enumeration of common event wildcards used in the EventBus.
 * This enumeration provides predefined wildcards for different
 * event categories, facilitating event subscription and management.
 *
 * Each wildcard represents a specific category or group of events
 * in the EventBus and is used as a pattern to match multiple events.
 *
 */
export const EventBusWildcards = {
  ALL: '*',
  CORE: 'core:**',
  ADD_TO_CART_HOOKS: 'core:addToCartHooks:**',
  LIVE_STREAMING_EVENT: 'event:**',
  EVENT_CHAT: 'event:chat:**',
  EVENT_PRODUCTS: 'event:products:**',
  CUSTOMIZE_LIVE: 'customize-live:**',
  PLAYER: 'player:**',
  SNACK_SIZE_VIDEO: 'ssv:**',
  SNACK_SIZE_VIDEO_ADMIN: 'ssv-admin:**',
  UI: 'ui:**',
  UTILS: 'utils:**',
} as const;

/**
 * Enumeration that defines the names of events used in the application.
 * This enumeration provides a list of event names used throughout
 * the application to facilitate event communication and management.
 *
 * Each event name should be unique and represent a specific action that
 * has occurred in the application.
 *
 */
export const EventNames = {
  EVENT_STATUS_CHANGED: 'event:statusChanged',
  /** AddToCartHook events */
  ATCH_ACTION_EXECUTED: 'core:addToCartHooks:actionExecuted',
} as const;
