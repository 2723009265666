import * as core from '@nizza/core';
import * as utils from '@nizza/utils';
import { initializeNizzaRuntime } from '@nizza/utils';
import env from '~env';
import logger from '~logger';

const runtime = initializeNizzaRuntime({ env, logger });

runtime.execute(() => {
  (window as any).nizza.core = core;
  (window as any).nizza.utils = utils;
  logger.info('🌟 Nizza runtime ready');
});
