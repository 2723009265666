/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { eventChatRoomService } from '../core';
import { useAccount } from '../../../accounts';

type Props = {
  eventId: string;
  userName: string;
  enabled?: boolean;
};

export const useEventChatRoom = (props: Props) => {
  const { eventId, userName, enabled = true } = props;
  const { accountName } = useAccount();
  const [token, setToken] = useState('');

  const refetch = () => {
    if (!enabled) {
      return;
    }
    if (!accountName) {
      return;
    }
    eventChatRoomService.setAccount(accountName);
    eventChatRoomService.get({ eventId, userName }).then(resp => {
      setToken(resp.token);
    });
  };

  useEffect(() => {
    refetch();
  }, [accountName, enabled]);

  return {
    getQuery: {
      refetch,
    },
    token,
  };
};

export default useEventChatRoom;
