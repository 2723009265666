import { CollectionGraphQLRepository } from './infrastructure';
import { CollectionService } from './services';

export * from './domain';
export * from './infrastructure';
export * from './services';

const repository = new CollectionGraphQLRepository();
const collectionService = new CollectionService(repository);

export { collectionService };
