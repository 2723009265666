import { getNizza } from '../nizza-store';
import { NizzaTimeZone } from '../types';
import { Logger } from './logger';

const { coreLogger } = getNizza();
const logger = Logger.withPrefix(coreLogger, 'getClientTimeZone');

/**
 * Get the browser's time zone.
 *
 * This function attempts to obtain the time zone and offset of the
 * browser using the Intl.DateTimeFormat and Date APIs.
 *
 * @returns {NizzaTimeZone} An object containing the offset, offsetValue, the time zone value.
 */
export function getClientTimeZone(): NizzaTimeZone {
  let timeZone: string | null = null;
  let offset: string | null = null;
  let offsetValue: number | null = null;

  try {
    // Try to get the browser's time zone
    timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? null;

    // Get the difference in minutes between local time and UTC
    const offsetMinutes: number = -new Date().getTimezoneOffset();
    offsetValue = offsetMinutes; // Store the difference in a variable

    // Determine the offset sign
    const sign: string = offsetMinutes >= 0 ? '+' : '-';

    // Calculate hours and minutes separately
    const hours: string = String(
      Math.floor(Math.abs(offsetMinutes) / 60),
    ).padStart(2, '0');
    const minutes: string = String(Math.abs(offsetMinutes) % 60).padStart(
      2,
      '0',
    );

    // Format the offset as a string in the format +/-HH:mm
    offset = `${sign}${hours}:${minutes}`;
  } catch (error) {
    // Handle any error that may occur
    logger.error('Error obtaining time zone or offset');
  }

  // Return an object with the time zone information and offset
  return {
    offset,
    offsetValue,
    value: timeZone,
    country: null,
    included: null,
    label: null,
  };
}
