import { useNZ } from '@nizza/core';
import { useCallback } from 'react';

/**
 * Custom hook with write operations for nizza EventBus.
 * @returns {object} EventBus.
 * @returns {object} - Object containing functions for emitting events and more.
 */
export function useEventBus() {
  const { eventBus } = useNZ();

  const emitEvent = useCallback(
    (event: string, data: any) => {
      eventBus.emit(event, data);
    },
    [eventBus],
  );

  return {
    emitEvent,
  };
}
