type Item = {
  variations?: (string | number)[];
  [key: string]: any;
};

/**
 * Builds a variation code string from an item object with variations and their names.
 *
 * @param {Item} item - The item object containing variations and their respective values.
 * @returns {string} - A string representing the variation codes, joined by '&'.
 */
export function buildVariationCodeWithNames(item: Item): string {
  if (!item?.variations) return '';

  return item.variations
    .filter(variation => item[variation])
    .map(variation => formatOption(String(variation), item[variation]))
    .join('&');
}

/**
 * Builds a variation code string from a record of options with their values.
 *
 * @param {Record<string, string>} options - The record containing key-value pairs to be formatted.
 * @returns {string} - A string representing the variation codes, joined by '&'.
 */
export function buildVariationCodeWithValues(
  options: Record<string, string>,
): string {
  return formatOptions(options);
}

/**
 * Formats a given key and its associated values into a string.
 *
 * @param {string} key - The key representing the variation.
 * @param {string[]} values - The array of values associated with the key.
 * @returns {string} - The formatted string in the form "key:value1&value2&value3".
 */
function formatOption(key: string, values: string[]): string {
  return `${key}:${values.join('&')}`;
}

/**
 * Formats options from a given record of key-value pairs into a string.
 *
 * @param {Record<string, string>} options - The record containing key-value pairs to be formatted.
 * @returns {string} - The formatted string with each key-value pair joined by '&'.
 */
function formatOptions(options: Record<string, string>): string {
  return Object.entries(options)
    .map(([key, value]) => `${key}:${value}`)
    .join('&');
}
