import Fuse from 'fuse.js';
import { collectionList, createCollection } from '../../utils';
import {
  Collection,
  CollectionInput,
  CollectionRepository,
  CollectionSearchInput,
  CollectionUpdateInput,
  ProductToSync,
} from '../domain';

const store: Collection[] = collectionList;
const fuse = new Fuse(store, { keys: ['name'] });

export class CollectionMemoryRepository implements CollectionRepository {
  account!: string;

  async find(id: string) {
    const collection = store.find(x => x.id === id);
    return collection ?? null;
  }

  async search(input: CollectionSearchInput = {}): Promise<Collection[]> {
    if (!input.search) return [];
    const res = fuse.search(input.search);
    return res.map(x => x.item);
  }

  async getAll() {
    return store;
  }

  async create(input: CollectionInput) {
    const collection = createCollection(input);
    store.push(collection);
    return collection;
  }

  async update(input: CollectionUpdateInput) {
    const { id, ...rest } = input;
    const collection = store.find(x => x.id === id);
    collection?.root.setProps(rest);
  }

  async delete(id: string) {
    const collectionIndex = store.findIndex(x => x.id === id);
    store.splice(collectionIndex, 1);
  }

  manageProduct(data: ProductToSync): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
}
