import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { ChatMessageBase } from '../../../types';
import { chatIsConnectedAtom, chatSocketAtom } from '../state';

/**
 * Write operations that can be performed with the event chat room.
 */
export const useChatSocketActions = () => {
  const chatSocket = useAtomValue(chatSocketAtom);
  const chatIsConnected = useAtomValue(chatIsConnectedAtom);

  const sendMessage = useCallback(
    (content: string, attributes: ChatMessageBase) => {
      if (!chatSocket || !chatIsConnected) return;
      const message = {
        id: attributes.id,
        sendDate: new Date(),
        content,
        action: 'SEND_MESSAGE',
        attributes,
      };
      const strData = JSON.stringify(message);
      chatSocket.send(strData);
    },
    [chatIsConnected, chatSocket],
  );

  return {
    sendMessage,
  };
};

export default useChatSocketActions;
