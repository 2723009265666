import {
  NizzaGetCollectionHandler,
  NizzaGetProductHandler,
  NizzaGetProductListHandler,
  NizzaProduct,
  NizzaProductDataSource,
  NizzaProductDataSourceConfig,
  getNizza,
} from '@nizza/core';
import {
  Product,
  collectionService,
  productService,
} from '@nizza/event-products';

export class PlatformProductDataSource implements NizzaProductDataSource {
  private accountName: string;

  constructor(readonly config: NizzaProductDataSourceConfig) {
    const { accountName } = getNizza();
    if (!accountName) throw new Error('Nizza account is invalid');
    this.accountName = accountName;
  }

  getProduct: NizzaGetProductHandler = async input => {
    const { productId } = input;

    if (!this.accountName) return null;

    try {
      productService.setAccount(this.accountName);
      const data = await productService.find(productId);
      return this.toNizzaProduct(data);
    } catch (error) {
      console.error(`Error finding product with id: ${productId}`, error);
      return null;
    }
  };

  getProductList: NizzaGetProductListHandler = async (input = {}) => {
    const collection = await this.getCollection(input);
    return collection?.products;
  };

  getCollection: NizzaGetCollectionHandler = async (input = {}) => {
    const { collectionId } = input;

    const collectionInfo = {
      id: input?.collectionId ?? '',
      name: '',
      products: [],
    };

    if (!collectionId) {
      console.error(new Error(`collectionId '${collectionId}' not valid`));
      return collectionInfo;
    }

    if (!this.accountName) {
      console.error(new Error(`account not valid`));
      return collectionInfo;
    }

    collectionService.setAccount(this.accountName);
    const collection = await collectionService.find(collectionId);

    return {
      ...collectionInfo,
      name: collection.name ?? '',
      products: collection?.products.map(this.toNizzaProduct),
    };
  };

  private toNizzaProduct = (product: Product): NizzaProduct => ({
    id: product.id,
    name: product.title,
    price: product.price,
    priceWithDiscount: product.salesPrice || product.price,
    imageUrl: product.pictures[0],
    addToCartLink: product.link,
    isAvailable: product.status === 'active',
    variationSelector: [],
    pdpLink: product.link,
    skuId: product.id,
    items: [],
  });
}
