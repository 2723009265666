import { NizzaProduct } from '@nizza/core';

const addHttp = (url: string) => {
  const httpRegex = /^https?:\/\//i;
  return httpRegex.test(url) ? url : `https://${url}`;
};

const replaceHost = (url: URL, host: string) =>
  url.href.replace(url.host, host);

export const fixLinkHost = (data: NizzaProduct, host: string): NizzaProduct => {
  try {
    const { pdpLink, addToCartLink } = data;

    const pdpLinkUrl = new URL(pdpLink);
    const addToCartLinkUrl = new URL(addToCartLink);

    const pdpLinkWithHost = addHttp(replaceHost(pdpLinkUrl, host));
    const addToCartLinkWithHost = addHttp(replaceHost(addToCartLinkUrl, host));

    return {
      ...data,
      pdpLink: pdpLinkWithHost,
      addToCartLink: addToCartLinkWithHost,
    };
  } catch (error) {
    console.error('[fixHostToPdpLink] Error in setHostToPdpLink: ', error);
    return data;
  }
};
