export enum ProductStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export const enum SyncStatus {
  IDLE = 'idle',
  ERROR = 'error',
  SUCCESS = 'success',
}

interface BaseProps {
  title: string;
  account: string;
  link: string;
  pictures: string[];
  price: number;
  salesPrice?: number | null;
  externalId?: string | null;
}

export type ProductProps = BaseProps & {
  id: string;
  status: ProductStatus;
  createdAt: Date;

  // Generate props
  hasOffer: boolean;
  offer: number;
  detailLink: string;
  image: string;
  syncStatus: SyncStatus;
};

export interface ProductInput extends BaseProps {
  id?: string;
  status?: ProductStatus;
  createdAt?: string;
}

export type ProductUpdateInput = Partial<ProductInput>;
