export abstract class ValueObject<T> {
  protected _value!: T;

  constructor(value?: T) {
    if (value !== undefined) {
      this._value = value;
    }
  }

  get value() {
    return this._value;
  }
}
