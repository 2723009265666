import { isNil, path } from 'ramda';
import { InvalidNizzaGlobalObjectError } from '../errors';

/**
 * Validates that required properties are defined in a given state object.
 * It supports deep property paths (e.g., 'config.account').
 *
 * @param {Record<string, any>} state - The state object to be validated.
 * @param {string[]} requiredProps - An array of strings representing the property paths to be validated.
 * @throws {InvalidNizzaGlobalObjectError} Throws an error if a required property is missing or invalid.
 * @throws {Error} Throws an error if the input arguments are not valid.
 */
export function validateRequiredProps<T extends Record<string, any>>(
  state: T,
  requiredProps: string[],
) {
  requiredProps.forEach(propPath => {
    if (isNil(path(propPath.split('.'), state))) {
      throw new InvalidNizzaGlobalObjectError(propPath);
    }
  });
}
