import { NizzaAccount, NizzaPlan } from '../../domain';
import { RawPublicAccount } from '../dtos';

export function rawPublicAccountToDomain(raw: RawPublicAccount): NizzaAccount {
  const { host, ...rest } = raw;

  return {
    ...rest,
    host: host ?? null,
    plan: {} as NizzaPlan,
  };
}
