/* eslint-disable react-hooks/exhaustive-deps */
import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import env from '~env';
import { getNizza } from '../../../nizza-store';
import { ChatMessage } from '../../../types';
import { Logger } from '../../../utils';
import {
  chatIsConnectedAtom,
  chatMessageListAtom,
  chatSocketAtom,
} from '../state';
import { connectToSocket } from '../utils';
import useEventChatRoom from './use-event-chat-room.hook';

const { coreLogger } = getNizza();
const logger = Logger.withPrefix(coreLogger, 'useConnectChatSocket');

const socketUrl = env.publicUrls.wssIVSChat;
const MAX_RECONNECT_ATTEMPTS = 10;

interface Props {
  eventId: string;
  userName: string;
  enabled?: boolean;
  onMessage?: (message: ChatMessage) => void;
  onEvent?: (event: any) => void;
}

const exponentialBackoff = (attempt: number) =>
  Math.min(10000, 1000 * Math.pow(2, attempt));

const processSocketMessage = (
  event: MessageEvent,
  onEvent?: (event: any) => void,
  onMessage?: (message: ChatMessage) => void,
) => {
  const data = JSON.parse(event.data);
  switch (data.Type) {
    case 'EVENT':
      onEvent?.(data);
      break;
    case 'MESSAGE':
      onMessage?.(data);
      break;
    case 'ERROR':
      logger.error('Chat room error:', data);
      break;
    default:
      logger.error('Unknown chat room event:', event);
  }
};

/**
 * Create a connection to the chat room of the event.
 * It handles reconnection with exponential backoff and limits the number of reconnection attempts.
 *
 * This hook is only intended for read operations, it receives
 * messages and events from the room and exposes them in Jotai atoms.
 *
 * For best performance it should be started once in a parent component.
 * To obtain the list of messages and the list of events use the Jotai atoms.
 *
 * NOTE: There is another hook called useChatSocketActions that contains reusable
 * write operations to avoid polluting the current *READ ONLY* hook!
 *
 * @param {Props} props - The properties to configure the chat connection.
 * @returns - An object containing the WebSocket, connection status, and message list.
 */
export const useConnectChatSocket = (props: Props) => {
  const { onMessage, eventId, enabled = true, userName, onEvent } = props;
  const { token, getQuery: getTokenQuery } = useEventChatRoom({
    eventId,
    userName,
    enabled,
  });
  const [socket, setChatSocket] = useAtom(chatSocketAtom);
  const [chatIsConnected, setChatIsConnected] = useAtom(chatIsConnectedAtom);
  const [chatMessageList, setChatMessageList] = useAtom(chatMessageListAtom);
  const reconnectAttempt = useRef(0);

  const handleSocketError = useCallback(
    (event: Event) => logger.error('Chat room socket error:', event),
    [],
  );

  const handleSocketOpen = useCallback(() => {
    setChatIsConnected(true);
    reconnectAttempt.current = 0;
    logger.debug('Successfully connected to the chat room');
  }, [setChatIsConnected]);

  const handleSocketClose = useCallback(() => {
    if (reconnectAttempt.current >= MAX_RECONNECT_ATTEMPTS) {
      logger.error(
        'Max reconnection attempts reached. No further attempts will be made.',
      );
      return; // Stop reconnection attempts
    }

    setChatIsConnected(false);
    reconnectAttempt.current += 1;
    const backoffTime = exponentialBackoff(reconnectAttempt.current);
    logger.debug(
      `Disconnected from chat room. Reconnecting in ${
        backoffTime / 1000
      } seconds... (Attempt ${reconnectAttempt.current})`,
    );

    setTimeout(() => getTokenQuery.refetch(), backoffTime);
  }, [getTokenQuery, setChatIsConnected]);

  /**
   * Attempts to establish a WebSocket connection to the chat room.
   * If the connection is already open or maximum reconnection attempts reached, it returns early.
   */
  const connectSocket = useCallback(() => {
    if (!token) {
      logger.debug('No token available. Connection aborted.');
      return;
    }

    if (socket?.readyState === WebSocket.OPEN) {
      logger.debug('WebSocket is already open. No need to reconnect.');
      return;
    }

    logger.debug('Connecting to the chat room...');
    const connection = connectToSocket({
      token,
      url: socketUrl,
      onOpen: handleSocketOpen,
      onClose: handleSocketClose,
      onError: handleSocketError,
      onMessage: event => processSocketMessage(event, onEvent, onMessage),
    });

    setChatSocket(connection);
  }, [
    token,
    socket,
    onEvent,
    onMessage,
    handleSocketOpen,
    handleSocketClose,
    handleSocketError,
    setChatSocket,
  ]);

  /**
   * Closes the WebSocket connection if it is open.
   * Returns early if no connection exists or is already closed.
   */
  const closeSocket = useCallback(() => {
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      logger.debug('No open socket to close.');
      return;
    }

    logger.debug('Closing chat room socket...');
    socket.close();
  }, [socket]);

  useEffect(() => {
    if (!enabled) {
      logger.debug('Chat is disabled, skipping connection.');
      return;
    }

    if (!token) {
      logger.debug('Token not available, skipping connection.');
      return;
    }

    logger.debug('Initializing connection with token:', token);
    closeSocket();
    connectSocket();

    return closeSocket;
  }, [token, enabled]);

  return {
    socket,
    chatIsConnected,
    chatMessageList,
    setChatMessageList,
  };
};

export default useConnectChatSocket;
