import env from '~env';
import { ApiV1Response } from '../../../../types';
import { customFetch, searchParams } from '../../../../utils';
import {
  AccountPlanRepository,
  CreatePlanError,
  NizzaPlan,
} from '../../domain';

const BASE_URL = `${env.apiUrls.restV1}/plans`;

export class RestAccountPlanRepository implements AccountPlanRepository {
  async get(planType: string) {
    const params = searchParams({ planType });
    const resource = `/info${params}`;
    const url = BASE_URL + resource;

    const { data } = await customFetch<ApiV1Response<NizzaPlan>>({ url });

    return data;
  }

  async create(input: NizzaPlan) {
    const url = `${BASE_URL}/create`;
    const { success } = await customFetch<ApiV1Response<void>>({
      url,
      method: 'POST',
      body: JSON.stringify(input),
    });

    if (!success) {
      throw new CreatePlanError(input);
    }
  }
}
