import {
  AccountInvalidError,
  ResourceNotFoundError,
  UnexpectedError,
} from '@nizza/core';
import {
  CollectionInput,
  CollectionRepository,
  CollectionSearchInput,
  CollectionUpdateInput,
  ProductListToSync,
} from '../domain';

type Synced = {
  id: string;
  synced: boolean;
};

type SyncedProducts = {
  added: Synced[];
  removed: Synced[];
};

export class CollectionService {
  constructor(private collectionRepo: CollectionRepository) {}

  async find(id: string) {
    this.validateAccount();

    try {
      const collection = await this.collectionRepo.find(id);

      if (!collection) {
        throw new ResourceNotFoundError(id);
      }

      return collection;
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }

  async search(
    input: CollectionSearchInput = {
      limit: 100,
    },
  ) {
    this.validateAccount();

    try {
      return input.search
        ? await this.collectionRepo.search(input)
        : await this.collectionRepo.getAll(input);
    } catch (error) {
      throw new UnexpectedError(error);
    }
  }

  async create(input: CollectionInput) {
    try {
      return await this.collectionRepo.create(input);
    } catch (error) {
      throw new UnexpectedError(error);
    }
  }

  async update(input: CollectionUpdateInput) {
    try {
      await this.collectionRepo.update(input);
    } catch (error) {
      throw new UnexpectedError(error);
    }
  }

  async delete(id: string) {
    this.validateAccount();

    try {
      await this.collectionRepo.delete(id);
    } catch (error) {
      throw new UnexpectedError(error);
    }
  }

  async syncProducts(input: ProductListToSync): Promise<SyncedProducts> {
    try {
      const { toAdd, toRemove } = input;

      const res = await Promise.allSettled([
        ...toAdd.map(x => this.collectionRepo.manageProduct(x)),
        ...toRemove.map(x => this.collectionRepo.manageProduct(x)),
      ]);

      return {
        added: res.slice(0, toAdd.length).map((x, i) => ({
          id: toAdd[i].productId,
          synced: x.status === 'fulfilled',
        })),
        removed: res.slice(toAdd.length).map((x, i) => ({
          id: toRemove[i].productId,
          synced: x.status === 'fulfilled',
        })),
      };
    } catch (error) {
      throw new UnexpectedError(error);
    }
  }

  setAccount(value: string) {
    this.collectionRepo.account = value;
  }

  private validateAccount() {
    if (!this.collectionRepo.account) throw new AccountInvalidError();
  }
}
