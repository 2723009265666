import { VtexProduct } from '@nizza/core';

export const filterAvailableVtexProducts = (product: VtexProduct) => {
  const availableItems = product?.items?.filter(item => {
    const availableSellers = item.sellers.filter(
      seller => seller.commertialOffer.IsAvailable,
    );
    return availableSellers.length > 0;
  });

  const firstItem = product.items?.[0];
  const item = availableItems?.[0] ?? firstItem;

  const seller =
    item?.sellers.find((seller: any) => seller.sellerDefault === true) ??
    firstItem.sellers[0];

  return {
    item,
    seller,
    isAvailable: !!availableItems?.length,
  };
};
