import { RuntimeType } from '../../types';
import { RuntimeStrategy, RuntimeStrategyConfig } from '../types';

const nzApp = (window as any)?.__NZ_APP__;

export class PlatformRuntime implements RuntimeStrategy {
  static type = RuntimeType.Platform;

  constructor(private config: RuntimeStrategyConfig) {}

  static isApplicable(): boolean {
    return nzApp === 'platform';
  }

  async execute(): Promise<void> {
    // Platform specific logic
  }
}
