import { DomainError } from './DomainError';

export class ResourceNotFoundError extends DomainError {
  constructor(id: string) {
    super(
      'RESOURCE_NOT_FOUND_ERROR',
      `Resource with id '${id}' not found.`,
      id,
    );
  }
}
