import {
  ApiV1Response,
  UTM,
  VtexOrderForm,
  customFetch,
  getNizza,
} from '@nizza/core';

export const setVtexUTM = async (utm: UTM) => {
  try {
    const { accountName } = getNizza();

    if (!accountName) throw new Error('Nizza account is invalid');

    const { orderFormId, marketingTags, utmSource } = utm;
    const url = `https://${accountName}.myvtex.com/_v/api/personal-shopper/miniCartMarketing/${orderFormId}`;

    await customFetch<ApiV1Response<VtexOrderForm>>({
      url,
      method: 'POST',
      body: JSON.stringify({
        marketingTags,
        utmSource,
      }),
    });
  } catch (e) {
    console.info('Error: ', e);
  }
};
