import { Logger } from '@nizza/core';
import logger from '~logger';
import { MemoryStorage } from './MemoryStorage';
import { isStorageAvailable, setProperty } from './utils';

// Custom implementation for local and session storage
export class CustomStorage {
  private logger = Logger.withPrefix(logger, 'CustomStorage');

  static init() {
    return new CustomStorage();
  }

  constructor() {
    if (window.top === window.self) {
      this.logger.debug('This is the main window');
    } else {
      this.logger.debug('This is NOT the main window');
    }

    this.replaceStorages();
  }

  private replaceStorages() {
    if (!this.isAvailable.localStorage) {
      const customLocalStorage = new MemoryStorage();
      setProperty(window, 'localStorage', customLocalStorage);
      this.logger.debug(
        'LocalStorage is not available, custom implementation is defined',
      );
    }

    if (!this.isAvailable.sessionStorage) {
      const customSessionStorage = new MemoryStorage();
      setProperty(window, 'sessionStorage', customSessionStorage);
      this.logger.debug(
        'SessionStorage is not available, custom implementation is defined',
      );
    }
  }

  get isAvailable() {
    return {
      localStorage: isStorageAvailable('localStorage'),
      sessionStorage: isStorageAvailable('sessionStorage'),
    };
  }
}

// export default CustomStorage.init();
