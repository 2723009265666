import { nanoid } from 'nanoid';

export const shortId = (length = 8) => nanoid(length);

/**
 * Constructs a search parameter string from a given object of key-value pairs.
 * Filters out undefined and optionally null values.
 *
 * @param {Record<string, any>} params - The object containing key-value pairs.
 * @param {boolean} [excludeNull=false] - Whether to exclude null values in addition to undefined.
 * @returns {string} The search parameter string formatted as '?key1=value1&key2=value2&key3=value3', or an empty string if no valid parameters are provided.
 *
 * @example
 * const params = {
 *   key1: 'value1',
 *   key2: 'value2',
 *   key3: undefined,
 *   key4: null,
 *   key5: 'value5'
 * };
 *
 * const searchParamString = searchParams(params, true);
 * console.log(searchParamString); // Output: "?key1=value1&key2=value2&key5=value5"
 */
export const searchParams = (
  params: Record<string, any>,
  excludeNull = false,
): string => {
  const strParams = Object.entries(params).reduce((acc, [k, v]) => {
    if (v !== undefined && (!excludeNull || v !== null)) {
      return `${acc}${acc ? '&' : ''}${k}=${v}`;
    }
    return acc;
  }, '');

  return strParams ? `?${strParams}` : '';
};

/**
 * Retrieves the URL search parameters from a specified script element's source URL.
 *
 * @param {string} id - The ID attribute of the script element.
 * @returns {URLSearchParams} - The URL search parameters obtained from the script's source URL.
 */
export const getScriptParams = (id: string): URLSearchParams => {
  const script = document.getElementById(id) as HTMLScriptElement;
  return script ? new URL(script.src).searchParams : new URLSearchParams();
};

export function fileOrigin(): string {
  const stacks = new Error().stack!.split('\n');
  if (stacks.length < 3) return '';
  return stacks[2].trim().substring(3); // remove 'at ' prefix
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
