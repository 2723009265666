import { Environment } from '@nizza/core';
import coreEnvs from '@nizza/core/envs/environment.prod';
import baseEnvs from './environment';

const config = {
  ...coreEnvs,
  ...baseEnvs,
};

export const environment: Environment<typeof config> = config;
export default environment;
