/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useMemo,
  DependencyList,
  EffectCallback,
} from 'react';
import { autorun, reaction } from 'mobx';

export interface UseSelectConfig<T> {
  defaultValue?: T;
  deps?: DependencyList;
}

/**
 * Hook that creates a MobX autorun that disposes when the component unmounts.
 *
 * @param effect - Function that runs when observable values change.
 * @param deps - Optional array of non-MobX dependencies.
 *
 * @example
 * useAutorun(() => {
 *   console.log(store.someValue);
 * }, [store]);
 */
export function useAutorun(effect: EffectCallback, deps: DependencyList = []) {
  useEffect(() => {
    const disposer = autorun(effect);
    return () => disposer(); // Clean-up on unmount
  }, deps);
}

/**
 * Hook that selects a value from a MobX store and reacts to changes.
 *
 * @param trigger - A function that returns the observable value to track.
 * @param config - Optional config object with `defaultValue` and `deps`.
 * @returns The current value selected from the store.
 *
 * @example
 * const accountName = useSelect(() => store.accountName, { defaultValue: 'Default Account' });
 * const items = useSelect(() => store.items, { deps: [dep1, dep2] });
 * const itemCount = useSelect(() => store.items.length);
 */
export function useSelect<T>(
  trigger: () => T,
  config: UseSelectConfig<T> = {},
): T {
  const { defaultValue, deps = [] } = config;

  const memoizedTrigger = useMemo(() => trigger, deps);
  const [value, setValue] = useState<T>(
    () => memoizedTrigger() || defaultValue!,
  );

  useEffect(() => {
    const disposer = autorun(() => setValue(memoizedTrigger()));
    return () => disposer();
  }, [memoizedTrigger, ...deps]);

  return value;
}

/**
 * Hook that reacts to changes in the selected data from MobX and triggers an effect.
 *
 * @param trigger - Function that returns the data to observe.
 * @param effect - Function to run when the observed data changes.
 * @param deps - Optional array of dependencies that may affect the reaction logic.
 *
 * @example
 * useReaction(
 *   () => store.someValue,
 *   (value) => console.log(`Value changed to: ${value}`),
 *   [dep1, dep2]
 * );
 */
export function useReaction<T>(
  trigger: () => T,
  effect: (value: T) => void,
  deps: DependencyList = [],
) {
  const memoizedTrigger = useMemo(() => trigger, deps);
  const memoizedEffect = useMemo(() => effect, deps);

  useEffect(() => {
    const disposer = reaction(memoizedTrigger, memoizedEffect);
    return () => disposer();
  }, [memoizedTrigger, memoizedEffect]);
}
