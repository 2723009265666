import log, { levels, LogLevelDesc, Logger as LogLevelLogger } from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

const isProd = import.meta.env.PROD;

export class Logger {
  private readonly provider = log;
  private readonly defaultLevel = this.getDefaultLevel();

  private static readonly LOG_METHODS = [
    'trace',
    'debug',
    'info',
    'warn',
    'error',
    'log',
  ] as const;

  constructor(private namePrefix = '') {
    this.namePrefix = namePrefix ? `${namePrefix}-` : '';

    this.provider.setDefaultLevel(this.defaultLevel);
    prefix.reg(log);

    prefix.apply(log, {
      template: '%l [%n]',
      nameFormatter: name => name?.toLowerCase() ?? '',
      timestampFormatter: () => new Date().toTimeString().slice(0, 8),
    });
  }

  createLogger(context: string): LogLevelLogger {
    const loggerName = `${this.namePrefix}${context.toLowerCase()}`;
    const logger = this.provider.getLogger(loggerName);
    const storedLevel = localStorage.getItem(
      `loglevel:${loggerName}`,
    ) as LogLevelDesc | null;

    logger.setLevel(storedLevel || this.defaultLevel);
    return logger;
  }

  static withPrefix(logger: LogLevelLogger, prefix: string): LogLevelLogger {
    const componentRegex = /\(\w+\)/;

    const handler = {
      get(target: LogLevelLogger, prop: string) {
        const originalMethod = target[prop as keyof LogLevelLogger];

        if (
          typeof originalMethod === 'function' &&
          Logger.LOG_METHODS.includes(prop as any)
        ) {
          return (...args: any[]) => {
            if (typeof args[0] === 'string' && !componentRegex.test(args[0])) {
              args[0] = `(${prefix}) ${args[0]}`;
            }
            return (originalMethod as any).apply(target, args);
          };
        }

        return originalMethod;
      },
    };

    return new Proxy(logger, handler);
  }

  private getDefaultLevel(): LogLevelDesc {
    const localStorageLevel = localStorage.getItem('loglevel');

    const logLevelMap = Logger.LOG_METHODS.reduce((acc, method) => {
      const upperCaseMethod = method.toUpperCase();
      acc[upperCaseMethod] = levels[upperCaseMethod as keyof typeof levels];
      return acc;
    }, {} as Record<string, number>);

    return (
      isProd
        ? logLevelMap[
            localStorageLevel?.toUpperCase() as keyof typeof logLevelMap
          ] ?? levels.INFO
        : levels.DEBUG
    ) as LogLevelDesc;
  }
}
