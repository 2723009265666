import {
  RestAccountPlanRepository,
  RestPublicAccountRepository,
} from './infrastructure';
import { RestPrivateAccountRepository } from './infrastructure/repositories/rest-private-account.repository';
import { AccountService } from './services';

const publicAccountRepository = new RestPublicAccountRepository();
const privateAccountRepository = new RestPrivateAccountRepository();
const planRepository = new RestAccountPlanRepository();

const accountService = new AccountService(
  publicAccountRepository,
  privateAccountRepository,
  planRepository,
);

export * from './domain';
export * from './infrastructure';
export * from './services';
export { accountService };
