import { ApiV2Response, customFetch, searchParams } from '@nizza/core';
import { omit } from 'ramda';
import envs from '~env';
import { ProductParamsInput, ProductREST, createProduct } from '../../utils';
import { Product, ProductRepository } from '../domain';

const BASE_URL = `${envs.apiUrls.restV2}/v1/product`;

export class ProductRestV2Repository implements ProductRepository {
  account!: string;

  async find(id: string): Promise<Product | null> {
    const params = searchParams({ account: this.account });
    const url = `${BASE_URL}/${id}${params}`;

    const { data } = await customFetch<ApiV2Response<ProductREST>>({
      url,
    });

    return createProduct(data);
  }

  async search(input: ProductParamsInput = {}): Promise<Product[]> {
    const params = searchParams({
      ...input,
      account: this.account,
    });

    const url = input.search
      ? `${BASE_URL}/search/${params}`
      : `${BASE_URL}${params}`;

    const { data, isArray } = await customFetch<ApiV2Response<ProductREST[]>>({
      url,
    });

    if (!isArray) return [];

    return data.map(x => createProduct(x));
  }

  async create(product: Product) {
    const url = BASE_URL;
    const data = omit(['id', 'createdAt'], product.toPrimitives());

    await customFetch({
      url,
      method: 'POST',
      body: JSON.stringify(data),
      raw: true,
    });
  }

  async update(product: Product) {
    const url = `${BASE_URL}/${product.id}`;
    const data = omit(['createdAt', 'id'], product.toPrimitives());

    await customFetch({
      url,
      method: 'PUT',
      body: JSON.stringify(data),
      raw: true,
    });
  }

  async delete(id: string) {
    const params = searchParams({ account: this.account });
    const url = `${BASE_URL}/${id}${params}`;

    await customFetch({
      url,
      method: 'DELETE',
      raw: true,
    });
  }
}
