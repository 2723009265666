import { ProductParamsInput } from '../../utils';
import { Product, ProductInput, ProductRepository } from '../domain';
import {
  AccountInvalidError,
  ResourceNotFoundError,
  UnexpectedError,
} from '@nizza/core';

// TODO: Implementar paginación y eliminar "limits" quemados
export class ProductService {
  constructor(private productRepo: ProductRepository) {}

  async find(id: string) {
    this.validateAccount();

    try {
      const product = await this.productRepo.find(id);

      if (!product) {
        throw new ResourceNotFoundError(id);
      }

      return product;
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }

  async search(input: ProductParamsInput = {}) {
    this.validateAccount();

    input = { limit: 500 };

    try {
      return await this.productRepo.search(input);
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }

  async create(input: ProductInput) {
    this.validateAccount();

    try {
      const product = Product.create(input);
      return await this.productRepo.create(product);
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }

  async update(product: Product) {
    this.validateAccount();

    try {
      await this.productRepo.update(product);
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }

  async delete(id: string) {
    this.validateAccount();

    try {
      await this.productRepo.delete(id);
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }

  setAccount(value: string) {
    this.productRepo.account = value;
  }

  private validateAccount() {
    if (!this.productRepo.account) throw new AccountInvalidError();
  }
}
