import { Logger as LogLevelLogger } from 'loglevel';
import { GlobalStore } from '../types';

/**
 * Creates a lazily instantiated logger using global store logging system. The actual logger
 * is only created and registered the first time one of its methods is invoked. This lazy
 * instantiation ensures efficient resource utilization by only initializing loggers when necessary.
 *
 * @param name The name of the logger, used for identification and filtering purposes.
 * @returns A proxy to the lazily instantiated logger.
 */
export function createLazyLogger(
  name: string,
  getStore: () => GlobalStore,
): LogLevelLogger {
  let loggerInstance: LogLevelLogger | null = null;

  /**
   * Ensures the logger is instantiated before any operations are performed.
   */
  const ensureLoggerInstantiated = () => {
    if (!loggerInstance) {
      loggerInstance = getStore().log.createLogger(name);
    }
  };

  /**
   * Checks if the property of the logger instance is a function.
   *
   * @param logger The logger instance.
   * @param property The property name to check.
   * @returns True if the property is a function; false otherwise.
   */
  const isFunctionProperty = (
    logger: LogLevelLogger,
    property: string,
  ): boolean => typeof logger[property as keyof LogLevelLogger] === 'function';

  /**
   * Handler for the proxy that intercepts all get operations. If the property accessed is a function,
   * it returns a wrapper function that defers the execution until it's actually called. For non-function
   * properties, it directly returns the property value.
   */
  const handler = {
    get(target: Record<string, any>, prop: string, receiver: any): any {
      ensureLoggerInstantiated();

      // Function to defer execution of logger methods.
      const functionHandler =
        (prop: string) =>
        (...args: any[]) => {
          if (!loggerInstance) return undefined; // Safety check to ensure loggerInstance exists.

          const method = loggerInstance[prop as keyof LogLevelLogger] as (
            ...args: any[]
          ) => any;

          if (typeof method === 'function') {
            return method.apply(loggerInstance, args);
          }
        };

      if (loggerInstance && isFunctionProperty(loggerInstance, prop)) {
        return functionHandler(prop);
      } else {
        // Returns the value for non-function properties.
        return loggerInstance
          ? loggerInstance[prop as keyof LogLevelLogger]
          : undefined;
      }
    },
  };

  // Returns a proxy that handles the lazy instantiation and method calls of the logger.
  return new Proxy({} as Record<string, any>, handler) as LogLevelLogger;
}
