import { Logger } from 'loglevel';
import { RenderHandler, RenderProps } from '../types';

export class ComponentManager<T = any> {
  private renderFn: RenderHandler;
  private logger: Logger;
  // Id of the root container, usually a <div>
  private rootId: string;
  element: T | null = null;
  unmount!: () => void;
  updateProps!: (props: RenderProps) => void;

  constructor(rootId: string, renderFn: RenderHandler, logger: Logger) {
    this.rootId = rootId;
    this.renderFn = renderFn;
    this.logger = logger;
  }

  async render(props: RenderProps = {}) {
    if (!this.element) {
      const renderRes = await this.renderFn(this.rootId, props);

      this.element = renderRes?.element ?? null;
      this.updateProps = props => renderRes?.updateProps(props);
      this.unmount = () => {
        renderRes?.unmount();
        this.element = null;
      };
    } else {
      this.updateProps(props);
    }
  }
}
